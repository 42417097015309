import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { GraphQLPage } from '@app/models/paging';

export class LoadSecureCoreProjects {
  static type = '[Teams] LoadSecureCoreProjects';

  constructor(
    public entityId: string,
    public searchProjectBy?: string,
    public graphQLPage?: GraphQLPage
  ) {}
}

export class SetSecureCoreProjectsLoading {
  static type = '[Teams] SetSecureCoreProjectsLoading';

  constructor(public loading: boolean) {}
}

export class SetSecureShipProjectsLoading {
  static type = '[Teams] SetSecureShipProjectsLoading';

  constructor(public loading: boolean) {}
}

export class SetSecureShipProjectsSearch {
  static type = '[Teams] SetSecureShipProjectsSearch';

  constructor(public value: string) {}
}

export class LoadSecureShipProjects {
  static type = '[Teams] LoadSecureShipProjects';

  constructor(
    public entityId: string,
    public filter?: string,
    public graphQLPage?: GraphQLPage,
  ) {}
}

export class LoadAiCertifyProjects {
  static type = '[Teams] LoadAiCertifyProjects';

  constructor(public entityId: string) {}
}

export class LoadCodeCertifyProjects {
  static type = '[Teams] LoadCodeCertifyProjects';

  constructor(public entityId: string) {}
}

export class LoadModule {
  static type = '[Teams] LoadModule';

  constructor(public entityId: string) {}
}

export class TeamsChangePage {
  static type = '[Teams] TeamsChangePage';

  constructor(
    public projectType: string,
    public pageEvent: PageEvent,
    public matPaginator: MatPaginator,
    public entityId: string
  ) {}
}

export class TeamsContainerChangePage {
  static type = '[Teams] TeamsContainerChangePage';

  constructor(
    public projectType: string,
    public pageEvent: PageEvent,
    public matPaginator: MatPaginator,
    public entityId: string
  ) {}
}

export class DeleteSecureCoreProject {
  static type = '[Teams] DeleteSecureCoreProject';

  constructor(
    public projectId: string,
    public entityId: string,
    public name: string
  ) {}
}

export class DeleteSecureShipProject {
  static type = '[Teams] DeleteSecureShipProject';

  constructor(
    public projectId: string,
    public entityId: string,
    public name: string
  ) {}
}

export class DeleteScpProject {
  static type = '[Teams] DeleteScpProject';

  constructor(
    public entityId: string,
    public entityIdToDelete: string,
    public name: string,
    public type: string
  ) {}
}

export class ClearEntityData {
  static type = '[Teams] CLearEntityData';
}
