import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { CommonUIMethodsDecorator } from '@app/core/decorators/common.decorator';
import { CoreHelperService } from '@app/services/core/core-helper.service';
import { User } from '@app/models';
import { AuthenticationService } from '@app/security/services';
import { InviteService } from '@app/services/invite.service';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from '@app/subscription/services/subscription.service';
import { environment } from '../../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BugReportService } from '@app/services/bug-report.service';
import { ToastrService } from 'ngx-toastr';
import version from '../../../../../../assets/version.json';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
})
@CommonUIMethodsDecorator()
export class NavRightComponent implements OnInit {
  public bugReportForm: FormGroup;
  public inviteUrl: string;
  public currentUser: User;

  public isHybrid: boolean = environment.hybrid;

  public requestTypeOptions = [
    { value: 'FEATURE_REQUEST', label: 'Feature Request' },
    { value: 'PRODUCT_ISSUE', label: 'Product Issue' },
  ];

  public priorityTypeOptions = [
    { value: 'HIGH', label: 'High' },
    { value: 'MEDIUM', label: 'Medium' },
    { value: 'LOW', label: 'Low' },
  ];

  public frontendVersion: {
    version: string;
  } = version;

  public constructor(
    public subscriptionService: SubscriptionService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public coreHelperService: CoreHelperService,
    private inviteService: InviteService,
    private fb: FormBuilder,
    private bugReportService: BugReportService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser;
    this.bugReportForm = this.fb.group({
      comment: ['', Validators.required],
      priorityType: ['', Validators.required],
      requestType: ['', Validators.required],
      subject: ['', Validators.required],
    });
  }

  async logout(): Promise<void> {
    await this.coreHelperService.logoutUser();
  }

  public inviteUser(inviteUrlDialog): void {
    this.inviteService.createInvite().subscribe(
      (data) => {
        const inviteHash = data.data.createInvite.inviteHash;

        this.router.navigate(['/admin/invite', inviteHash]);
      },
      (error) => {
        console.error('NavRightComponent', error);
      }
    );
  }

  public gotoProfile(): void {
    //Navigate to user detail page
    if (
      !!this.authenticationService.currentUser &&
      !!this.authenticationService.currentUser['username']
    ) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          userName: this.authenticationService.currentUser['username'],
        },
      };
      this.router.navigate(['/admin/user/show'], navigationExtras);
    }
  }

  public openBugReportModal(reportBugDialog): void {
    reportBugDialog.show();
  }

  public onReportSubmit(modal): void {
    if (this.bugReportForm.valid) {
      const requestData = this.bugReportForm.value;

      requestData.uxVersion = this.frontendVersion.version;
      this.bugReportService.createTicketRequest(requestData).subscribe(
        (data) => {
          if (data.data?.submitHubSpotTicket?.status === 'SUCCESS') {
            this.toastr.success('The ticket has been successfully submitted');
            this.bugReportForm.reset();
            modal.hide();
          } else {
            this.toastr.warning(
              'There has been a problem submitting the ticket'
            );
          }
        },
        (error) => {
          console.error(error);
          this.toastr.error('There has been an error submitting the ticket');
        }
      );
    } else {
      this.bugReportForm.markAllAsTouched();
    }
  }
}
