import { Injectable } from '@angular/core';
import {
  ComponentVersions,
  ComponentVersionsQuery,
  ComponentVersionsResult,
  ComponentWithVersions,
  ComponentWithVersionsDetailQuery,
  Copyright,
  License,
  ScanComponentWithVersionsQuery,
  TxComponent,
  Vulnerability,
} from '@app/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  FixVersionsDialog,
  LoadComponentDetails,
  LoadKbLatestComponentRelease,
  LoadNextComponentVersions,
  LoadPatchedVersions,
  SwitchVersion,
} from './component-detail.actions';
import { ScanComponentService } from '@app/services/scan-component.service';
import { map, switchMap, take } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import { ProjectBreadcrumbsService } from '@app/services/core';
import { PatchedInfoSimplified } from '@app/threat-center/shared/models/types';
import { FixService } from '@app/services/fix.service';
import { of } from 'rxjs';
import { KBService } from '@app/services/kb.service';
import * as _ from 'lodash';

export class ComponentDetailStateModel {
  component: TxComponent;
  componentId: string;
  group: string;
  licensesList: Array<{ node: License }>;
  name: string;
  releaseDate: string;
  version: string;
  vulnerabilityDetails: Array<Vulnerability>;
  breadcrumbDetail: any;
  patchedVersions: PatchedInfoSimplified;
  vulnerabilitiesSeveretyTotal: {
    CRITICAL: number;
    HIGH: number;
    MEDIUM: number;
    LOW: number;
    INFO: number;
    UNASSIGNED: number;
  };
  copyrightList: Array<Copyright>;
  componentVersions: ComponentVersions;
  componentVersionsLoading: boolean;
  loading: boolean;
  versionToFix: string;
}

type LocalStateContext = StateContext<ComponentDetailStateModel>;

export const DefaultVulnerabilitiesSeveretyTotal = {
  CRITICAL: 0,
  HIGH: 0,
  MEDIUM: 0,
  LOW: 0,
  INFO: 0,
  UNASSIGNED: 0,
};

export const vulnerabilitiesSortOrder = [
  'CRITICAL',
  'HIGH',
  'MEDIUM',
  'LOW',
  'INFO',
  'UNASSIGNED',
];

export const DefaultState = {
  component: null,
  componentId: '',
  group: '',
  licensesList: [],
  name: '',
  releaseDate: '',
  version: '',
  vulnerabilityDetails: [],
  breadcrumbDetail: {},
  patchedVersions: null,
  vulnerabilitiesSeveretyTotal: DefaultVulnerabilitiesSeveretyTotal,
  copyrightList: [],
  componentVersions: {
    resultList: [],
    pageSize: 0,
    start: 0,
    total: 0,
    currentindex: -1,
  },
  componentVersionsLoading: false,
  loading: false,
  versionToFix: '',
};

@State<ComponentDetailStateModel>({
  name: 'componentDetail',
  defaults: DefaultState,
})
@Injectable()
export class ComponentDetailState {
  public constructor(
    private scanComponentService: ScanComponentService,
    private projectBreadcrumbsService: ProjectBreadcrumbsService,
    private fixService: FixService,
    private store: Store,
    private kbService: KBService
  ) {}

  @Selector()
  static component(
    componentDetailState: ComponentDetailStateModel
  ): TxComponent {
    return componentDetailState.component;
  }

  @Selector()
  static componentVersions(
    componentDetailState: ComponentDetailStateModel
  ): Array<ComponentVersionsResult> {
    return componentDetailState.componentVersions
      ? componentDetailState.componentVersions.resultList
      : [];
  }

  @Selector()
  static currentComponentVersionIndex(
    componentDetailState: ComponentDetailStateModel
  ): number {
    return componentDetailState.componentVersions
      ? componentDetailState.componentVersions.currentIndex
      : 0;
  }

  @Selector()
  static vulnerabilities(
    componentDetailState: ComponentDetailStateModel
  ): Array<Vulnerability> {
    return componentDetailState.vulnerabilityDetails;
  }

  @Selector()
  static severetiesTotal(componentDetailState: ComponentDetailStateModel): {
    CRITICAL: number;
    HIGH: number;
    MEDIUM: number;
    LOW: number;
    INFO: number;
    UNASSIGNED: number;
  } {
    return componentDetailState.vulnerabilitiesSeveretyTotal;
  }

  @Selector()
  static licenses(
    componentDetailState: ComponentDetailStateModel
  ): Array<{ node: License }> {
    return componentDetailState.licensesList;
  }

  @Selector()
  static loading(componentDetailState: ComponentDetailStateModel): boolean {
    return componentDetailState.loading;
  }

  @Selector()
  static copyrights(
    componentDetailState: ComponentDetailStateModel
  ): Array<Copyright> {
    return componentDetailState.copyrightList;
  }

  @Selector()
  static breadcrumbs(componentDetailState: ComponentDetailStateModel): any {
    return componentDetailState.breadcrumbDetail;
  }

  @Selector()
  static patchedVersions(
    componentDetailState: ComponentDetailStateModel
  ): PatchedInfoSimplified {
    return componentDetailState.patchedVersions;
  }

  @Selector()
  static versionToFix(componentDetailState: ComponentDetailStateModel): string {
    return componentDetailState.versionToFix;
  }

  @Action(LoadComponentDetails)
  protected loadComponentDetails(
    ctx: LocalStateContext,
    action: {
      componentId: string;
      scanId: string;
      projectId: string;
      isComposite: boolean;
      defaultPageSize: number;
    }
  ): void {
    const state: ComponentDetailStateModel = ctx.getState();
    ctx.patchState({ loading: true });
    this.scanComponentService
      .getScanComponentWithAllVulnerabilities(
        action.scanId,
        action.componentId,
        action.projectId,
        action.isComposite,
        action.defaultPageSize
      )
      .pipe(
        map(
          (result: ApolloQueryResult<ScanComponentWithVersionsQuery>) =>
            result.data.scanComponent
        ),
        switchMap((res) => of(res)),
        take(1)
      )
      .subscribe((res: ComponentWithVersionsDetailQuery) => {
        if (this.projectBreadcrumbsService.getProjectBreadcrumb()) {
          this.projectBreadcrumbsService.settingProjectBreadcrumb(
            'Component',
            res.name,
            res.componentId,
            false
          );
        }

        const vulnerabilitiesSeveretyTotal = {
          ...DefaultVulnerabilitiesSeveretyTotal,
        };

        const clonedComponent = _.cloneDeep(res.component);
        if (clonedComponent) {
          if (clonedComponent.allVulns) {
            clonedComponent.allVulns.forEach((element) => {
              if (element.severity) {
                vulnerabilitiesSeveretyTotal[element.severity]++;
              }
            });
          }

          clonedComponent.supplyChainRiskScore = res['supplyChainRiskScore'];
          clonedComponent.componentType = res.componentType;
          clonedComponent.releaseDate = res.releaseDate;
          clonedComponent.maxSeverity = this.getMaxSeverety(
            clonedComponent.allVulns
          );

          // Process component versions
          let currentIndex = -1;
          clonedComponent.componentVersions.resultList.forEach(
            (componentVersion, index) => {
              // Add maxSeverity and vulnerability totals for each version
              componentVersion.vulnerabilities.maxSeverity =
                this.getMaxSeverety(componentVersion.vulnerabilities);

              componentVersion.vulnerabilitiesSeveretyTotal =
                this.calculateVulnerabilityTotals(
                  componentVersion.vulnerabilities
                );

              componentVersion.changeIcon = this.calculateChangeIcon(
                clonedComponent,
                componentVersion,
                vulnerabilitiesSeveretyTotal
              );

              if (componentVersion.changeIcon === 'current') {
                currentIndex = index;
              }
            }
          );

          clonedComponent.componentVersions.currentIndex = currentIndex;
        } else {
          Object.assign(clonedComponent, {
            name: res.name,
            group: res.group,
            version: res.version,
            releaseDate: res.releaseDate,
            componentId: res.componentId,
            componentType: res.componentType,
            supplyChainRiskScore: res['supplyChainRiskScore'],
          });
        }

        ctx.setState({
          ...state,
          component: clonedComponent,
          componentVersions: clonedComponent?.componentVersions || null,
          licensesList: this.removeDuplicates(res.licenses.edges),
          vulnerabilitiesSeveretyTotal,
          vulnerabilityDetails: clonedComponent?.allVulns || [],
          breadcrumbDetail:
            this.projectBreadcrumbsService.getProjectBreadcrumb(),
          copyrightList: clonedComponent?.copyrightList || [],
        });

        // Dispatch additional actions
        this.store.dispatch(new LoadPatchedVersions(action.componentId));
      });
  }

  private calculateVulnerabilityTotals(vulnerabilities): any {
    const totals = { ...DefaultVulnerabilitiesSeveretyTotal };
    vulnerabilities?.edges?.forEach((element) => {
      if (element.node?.severity) {
        totals[element.node.severity]++;
      }
    });
    return totals;
  }

  @Action(LoadKbLatestComponentRelease)
  protected loadKbLatestComponentRelease(
    ctx: LocalStateContext,
    action: {
      componentData: any;
    }
  ): void {
    const state: ComponentDetailStateModel = ctx.getState();
    ctx.patchState({ loading: true });

    this.kbService
      .getKbLatestComponentRelease(action.componentData.purl)
      .pipe(
        map(
          (result: ApolloQueryResult<any>) =>
            result.data.kbLatestComponentRelease
        ),
        switchMap((res) => of(res)),
        take(1)
      )
      .subscribe(
        (component) => {
          if (this.projectBreadcrumbsService.getProjectBreadcrumb()) {
            this.projectBreadcrumbsService.settingProjectBreadcrumb(
              'Component',
              component?.name,
              component?.componentId,
              false
            );
          }

          // Initialize vulnerabilities severity totals
          const vulnerabilitiesSeveretyTotal = {
            ...DefaultVulnerabilitiesSeveretyTotal,
          };

          // Clone the component to prevent mutation
          let clonedComponent = component ? _.cloneDeep(component) : null;

          if (clonedComponent) {
            // Process vulnerabilities
            if (clonedComponent.allVulns) {
              clonedComponent.allVulns.forEach((element: Vulnerability) => {
                if (element.severity) {
                  vulnerabilitiesSeveretyTotal[element.severity]++;
                }
              });
            }

            // Add additional properties to the cloned component
            clonedComponent.releaseDate = clonedComponent.releaseDate as any;
            clonedComponent.maxSeverity = this.getMaxSeverety(
              clonedComponent.allVulns
            );

            // Process component versions
            let currentIndex = -1;
            clonedComponent.componentVersions?.resultList.forEach(
              (componentVersion, index) => {
                // Add maxSeverity and vulnerability totals for each version
                componentVersion.vulnerabilities.maxSeverity =
                  this.getMaxSeverety(componentVersion.vulnerabilities);

                componentVersion.vulnerabilitiesSeveretyTotal = {
                  ...DefaultVulnerabilitiesSeveretyTotal,
                };

                componentVersion.vulnerabilities?.edges.forEach((element) => {
                  componentVersion.vulnerabilitiesSeveretyTotal[
                    element.node.severity
                  ]++;
                });

                componentVersion.changeIcon = this.calculateChangeIcon(
                  clonedComponent,
                  componentVersion,
                  vulnerabilitiesSeveretyTotal
                );

                // Identify the current version
                if (componentVersion.changeIcon === 'current') {
                  currentIndex = index;
                }
              }
            );

            clonedComponent.componentVersions.currentIndex = currentIndex;
          } else {
            // Fallback: Create minimal component structure
            clonedComponent = {
              name: component?.name || action.componentData.name,
              group: component?.group || action.componentData.group,
              version: component?.version || action.componentData.version,
              releaseDate:
                (component?.releaseDate as any) ||
                action.componentData.releaseDate,
              componentId:
                component?.componentId || action.componentData.componentId,
              componentType:
                component?.componentType || action.componentData.componentType,
            } as ComponentWithVersions;
          }

          // Update the state
          ctx.setState({
            ...state,
            component: clonedComponent,
            componentVersions: clonedComponent?.componentVersions || null,
            licensesList: this.removeDuplicates(
              component?.licenses.edges || []
            ),
            vulnerabilitiesSeveretyTotal,
            vulnerabilityDetails: clonedComponent?.allVulns || [],
            breadcrumbDetail:
              this.projectBreadcrumbsService.getProjectBreadcrumb(),
            copyrightList: clonedComponent?.copyrightList || [],
            loading: false,
          });
        },
        (error) => {
          console.error(error);
          const component = {
            name: action.componentData.name,
            group: action.componentData.group,
            version: action.componentData.version,
            releaseDate: action.componentData.releaseDate,
            componentId: action.componentData.componentId,
            componentType: action.componentData.componentType,
            errorData: true,
          } as ComponentWithVersions;
          ctx.patchState({
            loading: false,
            component,
            vulnerabilityDetails: [],
          });
        }
      );
  }

  @Action(LoadNextComponentVersions)
  protected loadNextComponentVersions(ctx: LocalStateContext) {
    const componentVersions: ComponentVersions = {
      ...ctx.getState().componentVersions,
    };
    const componentId = ctx.getState().component.componentId;
    const start: number = componentVersions.start + componentVersions.pageSize;
    if (
      start > componentVersions.total ||
      ctx.getState().componentVersionsLoading
    ) {
      return;
    }
    ctx.patchState({ componentVersionsLoading: true });
    this.scanComponentService
      .getComponentVersions(componentId, componentVersions.pageSize, start)
      .pipe(
        map(
          (result: ApolloQueryResult<ComponentVersionsQuery>) =>
            result.data.componentVersions
        )
      )
      .subscribe((res) => {
        const state = ctx.getState();
        componentVersions.pageSize = res.pageSize;
        componentVersions.total = res.total;
        componentVersions.start = res.start;
        res.resultList.forEach((componentVersion, index) => {
          componentVersion.vulnerabilities.maxSeverity = this.getMaxSeverety(
            componentVersion.vulnerabilities
          );
          componentVersion.vulnerabilitiesSeveretyTotal = {
            ...DefaultVulnerabilitiesSeveretyTotal,
          };
          componentVersion.vulnerabilities.edges.forEach((element) => {
            componentVersion.vulnerabilitiesSeveretyTotal[
              element.node.severity
            ] += 1;
          });
          componentVersion.changeIcon = this.calculateChangeIcon(
            state.component,
            componentVersion,
            state.vulnerabilitiesSeveretyTotal
          );
          if (componentVersion.changeIcon === 'current') {
            componentVersions.currentIndex = index + res.start;
          }
        });
        componentVersions.resultList = [
          ...componentVersions.resultList,
          ...res.resultList,
        ];

        ctx.patchState({
          componentVersions,
          componentVersionsLoading: false,
        });
        ctx.patchState({
          loading: false,
        });
      });
  }

  @Action(FixVersionsDialog)
  protected fixVersionsDialog(
    ctx: LocalStateContext,
    action: {
      versionToFix: string;
    }
  ): void {
    ctx.patchState({
      versionToFix: action.versionToFix,
    });
  }

  @Action(SwitchVersion)
  protected switchVersion(
    ctx: LocalStateContext,
    action: {
      purl: string;
    }
  ): void {
    const state: ComponentDetailStateModel = ctx.getState();
    ctx.patchState({
      loading: true,
    });
    this.kbService
      .getKbComponentRelease(action.purl)
      .pipe(
        map((result: ApolloQueryResult<any>) => result.data.kbComponentRelease),
        switchMap((res) => of(res)),
        take(1)
      )
      .subscribe(
        (component) => {
          if (this.projectBreadcrumbsService.getProjectBreadcrumb()) {
            this.projectBreadcrumbsService.settingProjectBreadcrumb(
              'Component',
              component.name,
              component.componentId,
              false
            );
          }

          const vulnerabilitiesSeveretyTotal = {
            ...DefaultVulnerabilitiesSeveretyTotal,
          };
          if (component && component['vulnerabilities']) {
            component['vulnerabilities'].edges.forEach((element) => {
              if (element.node?.severity) {
                vulnerabilitiesSeveretyTotal[element.node.severity] += 1;
              }
            });
          }

          const componentForStore = {
            name: null,
            group: null,
            version: null,
            releaseDate: null,
            componentId: null,
          } as ComponentWithVersions;
          if (component) {
            component['releaseDate'] = component.releaseDate as any;

            component['maxSeverity'] = this.getMaxSeverety(
              component['vulnerabilities']
            );

            let currentIndex = -1;
            component.componentVersions.resultList.forEach(
              (componentVersion, index) => {
                componentVersion.vulnerabilities.maxSeverity =
                  this.getMaxSeverety(componentVersion.vulnerabilities);
                componentVersion.vulnerabilitiesSeveretyTotal = {
                  ...DefaultVulnerabilitiesSeveretyTotal,
                };
                componentVersion.vulnerabilities.edges.forEach((element) => {
                  componentVersion.vulnerabilitiesSeveretyTotal[
                    element.node.severity
                  ] += 1;
                });
                componentVersion.changeIcon = this.calculateChangeIcon(
                  component,
                  componentVersion,
                  vulnerabilitiesSeveretyTotal
                );
                if (componentVersion.changeIcon === 'current') {
                  currentIndex = index;
                }
              }
            );
            component.componentVersions.currentIndex = currentIndex;
          } else {
            componentForStore.name = component.name;
            componentForStore.group = component.group;
            componentForStore.version = component.version;
            componentForStore.releaseDate = component.releaseDate as any;
            componentForStore.componentId = component.componentId;
            componentForStore.componentType = component.componentType;
            componentForStore['supplyChainRiskScore'] = component[
              'supplyChainRiskScore'
            ] as any;
          }

          if (component.vulnerabilities && component.vulnerabilities.edges) {
            component.vulnerabilities.edges =
              component.vulnerabilities.edges.map((vuln) => vuln.node);
          }

          ctx.setState({
            ...state,
            component: component || componentForStore,
            componentVersions: component ? component.componentVersions : null,
            licensesList: this.removeDuplicates(component.licenses.edges),
            vulnerabilitiesSeveretyTotal,
            vulnerabilityDetails: component?.vulnerabilities?.edges || [],
            breadcrumbDetail:
              this.projectBreadcrumbsService.getProjectBreadcrumb(),
            copyrightList: component ? component.copyrightList : [],
            loading: false,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  @Action(LoadPatchedVersions)
  protected loadPatchedVersions(
    ctx: LocalStateContext,
    action: {
      componentId: string;
    }
  ): void {
    const state: ComponentDetailStateModel = ctx.getState();

    this.fixService
      .getPatchedVersion(action.componentId)
      .pipe(take(1))
      .subscribe((patchedVersion: PatchedInfoSimplified) => {
        ctx.patchState({
          patchedVersions: patchedVersion,
          loading: false,
        });
      });
  }

  private removeDuplicates(licenses: Array<{ node: License }>) {
    const unique = licenses.filter(
      (license, index, selfLicenses) =>
        index ===
        selfLicenses.findIndex(
          (self) =>
            self.node.name === license.node.name &&
            self.node.category === license.node.category
        )
    );
    return unique;
  }

  private getMaxSeverety(
    vulnerabilities:
      | {
          edges: Array<{
            node: {
              vulnerabilityAlias: string;
              severity: string;
            };
          }>;
        }
      | Array<{
          vulnerabilityAlias: string;
          severity: string;
        }>
  ): string {
    // Handle empty cases
    if (
      !vulnerabilities ||
      (Array.isArray(vulnerabilities) && vulnerabilities.length === 0) ||
      (!Array.isArray(vulnerabilities) && vulnerabilities?.edges.length === 0)
    ) {
      return null;
    }

    // Extract the severity values based on input type
    const severities = Array.isArray(vulnerabilities)
      ? vulnerabilities.map((v) => v.severity)
      : vulnerabilities.edges.map((edge) => edge.node.severity);

    // Handle single vulnerability case
    if (severities.length === 1) {
      return severities[0];
    }

    // Find the maximum severity (minimum index in vulnerabilitiesSortOrder)
    let minIndex: number = vulnerabilitiesSortOrder.indexOf(severities[0]);
    for (let i = 1; i < severities.length; i++) {
      const currentIndex = vulnerabilitiesSortOrder.indexOf(severities[i]);
      if (currentIndex < minIndex) {
        minIndex = currentIndex;
      }
    }

    return vulnerabilitiesSortOrder[minIndex];
  }

  private calculateChangeIcon(
    component,
    componentVersion: ComponentVersionsResult,
    vulnerabilitiesSeveretyTotal
  ): string {
    if (component.version === componentVersion.version) {
      return 'current';
    }
    const componentMaxSeverety: string = this.getMaxSeverety(
      component.vulnerabilities
    );
    if (componentMaxSeverety && componentVersion.vulnerabilities.maxSeverity) {
      const componentMaxSeveretyIndex: number =
        vulnerabilitiesSortOrder.indexOf(componentMaxSeverety);
      const componentVersionMaxSeveretyIndex: number =
        vulnerabilitiesSortOrder.indexOf(
          componentVersion.vulnerabilities.maxSeverity
        );
      if (componentMaxSeveretyIndex < componentVersionMaxSeveretyIndex) {
        return 'lower';
      }
      if (componentMaxSeveretyIndex > componentVersionMaxSeveretyIndex) {
        return 'higher';
      }
      if (componentMaxSeveretyIndex === componentVersionMaxSeveretyIndex) {
        const componentSeveretiesCount =
          vulnerabilitiesSeveretyTotal[
            vulnerabilitiesSortOrder[componentMaxSeveretyIndex]
          ];
        const componentVersionSeveretiesCount =
          componentVersion.vulnerabilitiesSeveretyTotal[
            vulnerabilitiesSortOrder[componentVersionMaxSeveretyIndex]
          ];
        if (componentSeveretiesCount === componentVersionSeveretiesCount) {
          for (
            let i = componentMaxSeveretyIndex;
            i < vulnerabilitiesSortOrder.length;
            i++
          ) {
            const componentSeveretiesCountNext: number =
              vulnerabilitiesSeveretyTotal[vulnerabilitiesSortOrder[i]];
            const componentVersionSeveretiesCount: number =
              componentVersion.vulnerabilitiesSeveretyTotal[
                vulnerabilitiesSortOrder[i]
              ];
            if (
              componentSeveretiesCountNext !== componentVersionSeveretiesCount
            ) {
              return componentSeveretiesCountNext >
                componentVersionSeveretiesCount
                ? 'lower'
                : 'higher';
            }
          }
          return 'equal';
        }
        return componentSeveretiesCount > componentVersionSeveretiesCount
          ? 'lower'
          : 'higher';
      }
    }
    if (componentMaxSeverety && !componentVersion.vulnerabilities.maxSeverity) {
      return 'lower';
    }
    return 'equal';
  }
}
