import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import gql from 'graphql-tag';

@Injectable({ providedIn: 'root' })
export class BugReportService {
  public constructor(private coreGraphQLService: CoreGraphQLService) {}

  public createTicketRequest(createTicketData) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        mutation SubmitHubSpotTicket(
          $createTicketRequest: CreateTicketRequestInput
        ) {
          submitHubSpotTicket(createTicketRequest: $createTicketRequest) {
            data
            errorMessage
            status
          }
        }
      `,
      'no-cache',
      {
        createTicketRequest: {
          comment: createTicketData.comment,
          priorityType: createTicketData.priorityType,
          requestType: createTicketData.requestType,
          subject: createTicketData.subject,
          uxVersion: createTicketData.uxVersion,
        },
      }
    );
  }
}
