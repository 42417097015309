import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LicenseCategoryDirective } from './license-category.directive';
import { RepoIconDirective } from './repo-icon.directive';
import { SeverityBadgeDirective } from './severity-badge.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { LimitNumberDirective } from './limit-number.directive';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [
    RepoIconDirective,
    LicenseCategoryDirective,
    SeverityBadgeDirective,
    NumbersOnlyDirective,
    LimitNumberDirective,
    TooltipDirective,
  ],
  imports: [CommonModule],
  exports: [
    RepoIconDirective,
    LicenseCategoryDirective,
    SeverityBadgeDirective,
    NumbersOnlyDirective,
    LimitNumberDirective,
    TooltipDirective,
  ],
})
export class DirectivesModule {}
