<ng-container *ngIf="!item.hidden && checkPermissions(item)">
  <!-- MAIN MENU ITEMS -->
  <li
    [class]="'menu-item ' + item.classes"
    [ngClass]="{
      adaptable: item.adaptable
    }"
    *ngIf="item.url && !item.external"
    [routerLinkActive]="['active']"
  >
    <a
      class="nav-link"
      [target]="item.target ? '_blank' : '_self'"
      [routerLink]="[item.url]"
      (click)="closeOtherMenu($event)"
    >
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="pcoded-mtext">{{ item.title }}</span>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>

  <!-- EXTERNAL LINKS -->
  <li
    [class]="'menu-item ' + item.classes"
    [ngClass]="{
      adaptable: item.adaptable
    }"
    *ngIf="item.url && item.external"
  >
    <a [target]="item.target ? '_blank' : '_self'" [href]="item.url">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="pcoded-mtext">{{ item.title }}</span>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>

  <!-- ICON TEMPLATE -->
  <ng-template #itemIcon>
    <span *ngIf="item.icon" class="pcoded-micon">
      <i [ngClass]="item.icon"></i>
    </span>
  </ng-template>

  <!-- BADGE TEMPLATE -->
  <ng-template #itemBadge>
    <span *ngIf="item.badge" class="badge" [ngClass]="item.badge.type">
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>
