import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import {
  CheckAlreadyScannedQuery,
  CheckAlreadyScannedScpModuleQuery,
  ScanRequest,
  TaskQuery,
} from '@app/models';
import {
  RunningTaskCountQuery,
  RunningTaskCountSubscription,
} from '@app/threat-center/shared/models/types';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class TaskService {
  public scanRequest: ScanRequest;

  constructor(
    private apollo: Apollo,
    private coreGraphQLService: CoreGraphQLService
  ) {}

  submitScanRequest(projectType: string = '') {
    /*if(!this.scanRequest) {}
      // should reroute back to wizard.
      return;
    }*/
    const projectTypeParam = projectType ? `, projectType:"${projectType}"` : '';
    return this.coreGraphQLService.coreGQLReq<TaskQuery>(
      /* eslint-disable */
      /* fetchRepo(repoUrl: "${this.scanRequest.repositoryUrl}", login:"${this.scanRequest.login}",repository:"${this.scanRequest.repository}",branch:"${this.scanRequest.branch}",repoType:"${this.scanRequest.repoType}",projectId:"${this.scanRequest.projectId}",entityId:"${this.scanRequest.entityId}") { */
      gql`query {        
        fetchRepo(login:"${this.scanRequest.login}",repository:"${this.scanRequest.repository}",branch:"${this.scanRequest.branch}",projectId:"${this.scanRequest.projectId}",entityId:"${this.scanRequest.entityId}",registrationId:"${this.scanRequest.registrationId}", repositoryUrl:"${this.scanRequest.url}" ${projectTypeParam}) {
        taskToken,
        pctComplete,
        status,
        statusMessage,
        resourceId
        subtasks {
          taskToken,
          pctComplete,
          status,
          statusMessage,
          resourceId
        }
      }
    }`,
      'no-cache'
    );
    /* eslint-enable */
  }

  getTaskUpdate(taskToken: string) {
    return this.coreGraphQLService.coreGQLReq<TaskQuery>(
      gql` query {
      task_update(taskToken:"${taskToken}") {
      taskToken,
      pctComplete,
      status,
      statusMessage,
      resourceId
      subtasks {
        taskToken,
        pctComplete,
        status,
        statusMessage,
        resourceId
        }
      }
    }`,
      'no-cache'
    );
  }

  getRunningScanTasksCount(entityId: string) {
    return this.coreGraphQLService.coreGQLReq<RunningTaskCountQuery>(
      gql`  query {
      running_scan_tasks_count(entityId:"${entityId}")
    }`,
      'no-cache'
    );
  }

  // subscribe to running scan task count
  subscribeRunningScanTaskCount(entityId: string) {
    return this.apollo.subscribe<RunningTaskCountSubscription>({
      query: gql`subscription{
        subscribeRunningScanTaskCount(entityId:"${entityId}") {value, errors{
          message
        }}
      }`,
    });
  }

  // fetch if same project is already scanned
  checkAlreadyScannedProject(errorHandler) {
    return this.coreGraphQLService.coreGQLReq<CheckAlreadyScannedQuery>(
      /* eslint-disable */
      gql`query {
        checkAlreadyScannedProject(login:"${this.scanRequest.login}",repository:"${this.scanRequest.repository}",branch:"${this.scanRequest.branch}", entityId: "${this.scanRequest.entityId}", registrationId: "${this.scanRequest.registrationId}")
      }`,
      'no-cache',
      {},
      errorHandler
    );
    /* eslint-enable */
  }

  // fetch if same project is already scanned
  checkAlreadyScannedScpModule(errorHandler) {
    return this.coreGraphQLService.coreGQLReq<CheckAlreadyScannedScpModuleQuery>(
      /* eslint-disable */
      gql`query {
          checkAlreadyScannedScpModule(login:"${this.scanRequest.login}",repository:"${this.scanRequest.repository}",branch:"${this.scanRequest.branch}", scpProjectId: "${this.scanRequest.entityId}", registrationId: "${this.scanRequest.registrationId}") {
            scpProjectName
            scpProjectTags
            scpProjectType
            scpProjectId
            entityId
            scpProjectBranches
            orgId
          }
        }`,
      'no-cache',
      {},
      errorHandler
    );
    /* eslint-enable */
  }
}
