import { Repository } from './repository';
import { StringHolder } from '@app/threat-center/shared/models/types';
import { PageInfo } from '@app/models/paging';
import { ScanVulnerabilityEdge, Vulnerability } from './vulnerability';
import { License } from './license';
import { ScanAssetMatchCopyright } from './copyright';
import { ScpAssetType } from './assets';
import { RepositoryCode } from './repository-code';

// todo: ref: duplicate class declaration (keep this one, remove another)
export class ScanAsset {
  orgId: string;
  scanId: string;
  name: string;
  size: number;
  fileSize: number;
  scanAssetId: string;
  originAssetId: string;
  workspacePath: string;
  status: string;
  scanAssetType: string;
  content: string;
  projectId: string;
  matchRepository: Repository;
  scanAssetMatches: ScanAssetMatch[];
  scanAssetMatchesByRepo?: ScanAssetMatch[];
  embeddedAssets: any;
  attributionStatus: string;
  isSupportedLicenseAnnotation?: boolean;
  assetRepositoryUrl: StringHolder;
  sourceAssetAttribution: {
    attributedDate: Date;
    attributedBy: string;
    attributedComment: string;
    attributionStatus: string;
  };
  assetSize: number;
  projectPath?: string;
  ignored?: boolean;
  averagePercentCopied?: number;
  matchCount?: number;
  matchScore?: 'HIGH' | 'MEDIUM' | 'LOW';
  percentEmbedded?: number;
  scanAssetLicenses?: any;
  scanAssetCopyrights?: any;
  rejected?: boolean;
  reviewed?: boolean;
  otCount?: number;
  assetType?: ScpAssetType;
  assetPath?: string;
  componentPurl?: string;
  scanAssetForArchive?: string;
  binaryType?: string;
}

export interface ScanAssetMatchGroup {
  assetMasterMatchId: string;
  assetMatchId: string;
  assetSize: number;
  earliestReleaseDate: Date;
  earliestReleaseVersion: string;
  latestReleaseDate: Date;
  latestReleaseVersion: string;
  name: string;
  orgId: string;
  originAssetId: string;
  percentMatch: number;
  scanAssetId: string;
  scanId: string;
}

export class MatchGroupConnection {
  edges: MatchGroupEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

export class MatchGroupEdge {
  node: MatchGroup;
  cursor: string;
}

export class MatchGroup {
  name: string;
  assetMatchId: string;
  earliestReleaseDate: Date;
  earliestReleaseVersion: string;
  latestReleaseDate: Date;
  latestReleaseVersion: string;
}

// todo: ref: duplicate class declaration (keep this one, remove another)
export class ScanAssetMatch extends ScanAsset {
  orgId: string;
  scanId: string;
  scanAssetId: string;
  assetMatchId: string;
  name: string;
  reviewed: boolean;
  rejected: boolean;
  auditorFullName: string;
  auditorRecommends: string;
  percentMatch: number;
  matchGroups: MatchGroupConnection;
  repositoryPath: string;
  percentCopied: number;
  assetSize: number;
  earliestReleaseVersion: string;
  latestReleaseVersion: string;
  // matchRepository?: Repository;
  scanAssetMatchLicenses?: Array<License>;
  matchCopyrights?: Array<ScanAssetMatchCopyright>;
  matchLicenses?: any;
  auditDate?: Date;
}

export type ScanAssetQuery = {
  scanAsset: ScanAsset;
};

export class ScanAssetMatchRequest {
  readonly assetMatchId: string;
  readonly percentMatch: number;
  constructor(assetMatchId: string, percentMatch: number) {
    this.assetMatchId = assetMatchId;
    this.percentMatch = percentMatch;
  }
}

export class AttributeAssetRequestInput {
  readonly scanId: string;
  readonly scanAssetId: string;
  readonly licenseIds: any[];
  readonly attributeComment: string;
  constructor(
    scanId: string,
    scanAssetId: string,
    licenseIds: any[],
    attributeComment: string
  ) {
    this.scanId = scanId;
    this.scanAssetId = scanAssetId;
    this.licenseIds = licenseIds;
    this.attributeComment = attributeComment;
  }
}

export interface AssetAttributionResult {
  assetAttributionResults: [
    {
      assetId: string;
      assetMatchesAttributions: Array<{
        assetId: string;
        assetMatchId: string;
        matchLicenseAttributions: Array<{
          assetId: string;
          assetMatchId: string;
          attributionStatus: string;
          licenseId: string;
          scanId: string;
        }>;
        scanId: string;
      }>;
      attributionStatus: string;
      scanId: string;
    }
  ];
  assetsProcessedWithErrors: any;
  attributionStatus: string;
  message: string;
  repoPullRequests: null;
}

export class ScanAssetsTreeConnection {
  edges: ScanAssetsTreeEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

export class ScanAssetsTreeEdge {
  node: ScanAsset;
  cursor: string;
}

export class ScanLicenseAssetConnection {
  edges: ScanLicenseAssetEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

export class ScanLicenseAssetEdge {
  node: any; // ScanComponentLicenseEdge
  cursor: string;
}

export class ScanLicenseAsset {
  orgId: string;
  scanId: string;
  licenseId: string;
  scanAssetId: string;
}

export class ScanComponentConnection {
  edges: ScanComponentEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

export class ScanComponentEdge {
  node: ScanComponent;
  cursor: string;
}

export interface ScanComponent {
  component?: any;
  componentDiscoveryMethod: string; // "DECLARED"
  componentId: string;
  componentLocation: string; //"DEPENDENCY_FILE"
  componentType: string; // "LIBRARY"
  copyrights?: Array<any>;
  copyright: string;
  group: string;
  hasVulnerability: boolean;
  isInternal: boolean;
  license: string;
  licenses: ScanComponentLicenseEdge;
  metrics?: string;
  name: string;
  orgId: string;
  purl: string;
  releaseDate: Date;
  rejected?: boolean;
  ignored?: boolean;
  scanId: string;
  version: string;
  vulnerabilities: ScanVulnerabilityEdge;
  allVulnerabilities?: Array<Vulnerability>;
  projectPath: string;
  scanComponentCopyrights?: any;
  auditorRecommends?: any;
  reviewed?: boolean;
  fullName?: string;
  auditDate?: Date;
  auditorFullName?: string;
  auditorUsername?: string;
  componentPurl?: string;
  minDepth: number;
  autofixPullRequest?: string;
  dependencyManagerType?: RepositoryCode;
  workspaceRelativeFilePath?: string;
  vulnLinkCorrect?: boolean;
  policyViolationNotation?: Array<{ policyName: string; policyId: string }>;
  orgComponentId?: string;
}

export interface ScanComponentLicenseEdge {
  edges: Array<{ node: License }>;
}

export interface ScanAssetCustomMatchRequest {
  opensourceAssetURL: string;
  percentMatch: number;
  matchScore: number;
  percentCopied: number;
  scanId: string;
  scanAssetId: string;
  earliestReleaseDate: Date;
  earliestReleaseVersion: string;
  latestReleaseDate: Date;
  latestReleaseVersion: string;
  licenses: Array<string>;
  copyright: string;

  assetMatchId: string;
  assetMasterMatchId: string;
  knownAsset: boolean;
  knownAssetMessage: string;
  assetMatched: boolean;
}

export interface CheckCustomMatchAssetURLRequest {
  opensourceAssetURL: string;
  scanId: string;
  scanAssetId: string;
}

export interface CheckCustomMatchAssetURLResponse {
  assetMatchId: string;
  assetMasterMatchId: string;

  knownAsset: boolean;
  knownAssetMessage: string;
  assetMatched: boolean;
  assetMatchedMessage: string;

  percentMatch: number;
  matchScore: number;
  percentCopied: number;

  earliestReleaseDate: Date;
  earliestReleaseVersion: string;
  latestReleaseDate: Date;
  latestReleaseVersion: string;
  licenses: Array<License>; // array of licenseId
  copyrights: string; // lines of copyrights (separated by new line delimiter)
}
