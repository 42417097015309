import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import {
  MatSnackBarConfig,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';

import { AppRoutingModule } from './app-routing.module';
import { ThemeSharedModule } from './theme/shared/theme-shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

import {
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbButtonsModule,
  NgbDropdownModule,
  NgbModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TcSharedModule } from './threat-center/shared/tc-shared.module';
import { SharedModule } from './shared/shared.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor, ErrorInterceptor } from './security/helpers';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { UnauthorizedComponent } from '@app/security/unauthorized/unauthorized.component';
import { CookieService } from 'ngx-cookie-service';
import { LoadingDialogComponent } from './threat-center/dashboard/project-scan-dialog/loading-dialog.component';
import { PreScanLoadingDialogComponent } from './threat-center/dashboard/pre-scan-dialog/pre-scan-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { OngoingOperationsService } from './services/ongoing-operations.service';
import { AnnotationService } from './services/annotation.service';
import { AuthenticationService } from './security/services';
import { ReloadService } from './services/reload.service';
import { ScanHelperService } from './services/scan-helper.service';
import { TaskService } from './services/task.service';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { GlobalErrorHandler } from './security/helpers/global-error-hadler';
import { environment } from 'environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

const MAT_SNACK_BAR_OPTIONS: MatSnackBarConfig = {
  duration: 2500,
  horizontalPosition: 'end',
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    LoadingDialogComponent,
    PreScanLoadingDialogComponent,
    UnauthorizedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ThemeSharedModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    TcSharedModule,
    GraphQLModule,
    HttpClientModule,
    CoreModule,
    ToastrModule.forRoot(),
    MatProgressBarModule,
    NgbModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgxScrollTopModule,
    NgxsModule.forRoot([], {
      selectorOptions: { injectContainerState: false, suppressErrors: false },
      developmentMode: !environment.production,
      executionStrategy: NoopNgxsExecutionStrategy,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: MAT_SNACK_BAR_OPTIONS,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    CookieService,
    OngoingOperationsService,
    AnnotationService,
    AuthenticationService,
    ReloadService,
    ScanHelperService,
    TaskService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
