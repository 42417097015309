import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'purlParser',
})
export class PurlParserPipe implements PipeTransform {
  transform(
    purl: string,
    field:
      | 'purlType'
      | 'namespace'
      | 'name'
      | 'version'
      | 'subpath'
      | 'qualifiers'
  ): string | Record<string, string> | null {
    if (!purl || !field) {
      return null;
    }

    const regex =
      /^pkg:([^\/]+)(?:\/([^\/@#?]+))?\/([^@#?]+)(?:@([^#?]+))?(?:#([^?]+))?(?:\?(.*))?$/;
    const match = purl.match(regex);

    if (!match) {
      return null; // Invalid PURL format
    }

    const [, purlType, namespace, name, version, subpath, qualifiers] = match;

    // Parse qualifiers into an object
    const qualifiersObj: Record<string, string> = {};
    if (qualifiers) {
      qualifiers.split('&').forEach((pair) => {
        const [key, value] = pair.split('=');
        qualifiersObj[decodeURIComponent(key)] = decodeURIComponent(value);
      });
    }

    const fields: Record<string, string | Record<string, string> | null> = {
      purlType,
      namespace: namespace || null, // Namespace may be missing
      name,
      version: version || null,
      subpath: subpath || null,
      qualifiers: Object.keys(qualifiersObj).length ? qualifiersObj : null,
    };

    return fields[field] || null;
  }
}
