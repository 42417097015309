import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortenName' })
export class ShortenName implements PipeTransform {
  /**
   *
   * @param value - some text (title/name/etc.)
   * @param length - desired lenght of displayed text
   */
  transform(
    value: string,
    maxLength: number = 50,
  ): string {
    if (!value || value.length <= maxLength) {
      return value;
    }

    const startLength = Math.ceil((maxLength - 6) / 2);
    const endLength = Math.floor((maxLength - 6) / 2);

    return value.substring(0, startLength) + '......' + value.substring(value.length - endLength);
  }
}
