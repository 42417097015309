import {
  ScanAsset,
  ScanAssetCustomMatchRequest,
  ScanAssetMatch,
} from '@app/models';
import { GraphQLPage } from '@app/models/paging';

export class LoadAssetList {
  static type = '[AssetList] LoadAssetList';

  constructor(
    public entityId: string,
    public scanId: string,
    public assetPath: string,
    public graphQLPage?: GraphQLPage,
    public scanAssetId?: string
  ) {}
}

export class LoadNextPageAssetList {
  static type = '[AssetList] LoadNextPageAssetList';
}

export class ReviewAssetFromList {
  static type = '[AssetList] ReviewAssetFromList';

  constructor(
    public asset: ScanAsset,
    public actionType: 'approve' | 'ignore' | 'reject' | 'revert'
  ) {}
}

export class ReviewScanAssetMatch {
  static type = '[AssetList] ReviewScanAssetMatch';

  constructor(
    public scanAssetMatch: ScanAssetMatch,
    public scanAsset: ScanAsset,
    public actionType: 'approve' | 'ignore' | 'reject' | 'revert'
  ) {}
}

export class UpdateScanAssetValue {
  static type = '[AssetList] UpdateScanAssetValue';

  constructor(
    public asset: ScanAsset,
    public field: string,
    public value: any
  ) {}
}

export class UpdateScanAssetMatchValue {
  static type = '[AssetList] UpdateScanAssetMatchValue';

  constructor(
    public asset: ScanAssetMatch,
    public field: string,
    public value: any
  ) {}
}

export class SetFilterStringForList {
  static type = '[AssetList] SetFilterStringForList';

  constructor(public filterString: string) {}
}

export class ClearList {
  static type = '[AssetList] ClearList';

  constructor() {}
}

export class AttributeAsset {
  static type = '[AssetList] AttributeAsset';

  constructor(
    public selectedLicenses: any,
    public ignore: boolean,
    public selectedAsset: ScanAsset,
    public selectedLicensesArray,
    public attributionComment: string,
    public attributeProcessExecuteMessageModel: any
  ) {}
}

export class AddCustomMatchToAsset {
  static type = '[AssetList] AddCustomMatchToAsset';

  constructor(
    public asset: ScanAsset,
    public customMatchData: ScanAssetCustomMatchRequest
  ) {}
}
