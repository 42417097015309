<div class="matrix-chart-card {{ chartClass }}">
  <div class="matrix-chart-top">
    <h6>{{ chartData.title }}</h6>
    <i class="far fa-chart-bar fa-lg"></i>
  </div>

  <div class="matrix-chart-btm">
    <div class="matrix-chart-blk">
      <apx-chart
        #apxChart
        [autoUpdateSeries]="false"
        [series]="chartData.series"
        [labels]="chartData.labels"
        [colors]="chartData.colors"
        [fill]="chartData.fill"
        [chart]="chartConfig.chart"
        [plotOptions]="chartConfig.plotOptions"
        [legend]="chartConfig.legend"
        [responsive]="chartConfig.responsive"
        [dataLabels]="chartConfig.dataLabels"
        [stroke]="{ width: 1 }"
      ></apx-chart>
    </div>

    <div class="matrix-chart-legend">
      <ng-container *ngFor="let data of chartData.chartLabels; index as i">
        <ng-container
          *ngIf="!!chartData.series[i] && chartData['series'][i] !== 0"
        >
          <p>
            <span [ngClass]="data.class">{{ data.label }}</span>
            <small>{{ chartData.series[i] }}</small>
          </p>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
