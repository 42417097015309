import {
  Directive,
  ElementRef,
  Input,
  HostListener,
  OnDestroy,
  Component,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-tooltip-content',
  template: `<div class="custom-tooltip">{{ text }}</div>`,
  styles: [
    `
      .custom-tooltip {
        background: #00acc1;
        color: white;
        padding: 5px 10px;
        border-radius: 2px;
        font-size: 14px;
        opacity: 0.9;
        max-width: 200px;
      }
    `,
  ],
})
export class TooltipContentComponent {
  text: string = '';
}

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input('appTooltip') text = '';

  private overlayRef: OverlayRef | null = null;

  constructor(private overlay: Overlay, private elementRef: ElementRef) {}

  @HostListener('mouseenter')
  showTooltip() {
    if (this.overlayRef) {
      return;
    }

    // Create the overlay
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ]),
      hasBackdrop: false,
    });

    // Attach the tooltip component to the overlay
    const tooltipPortal = new ComponentPortal(TooltipContentComponent);
    const tooltipRef = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.text = this.text;
  }

  @HostListener('mouseleave')
  hideTooltip() {
    this.overlayRef?.dispose();
    this.overlayRef = null;
  }

  ngOnDestroy() {
    this.overlayRef?.dispose();
  }
}
