import { Component, Input, OnInit } from '@angular/core';
import {
  ComponentVersions,
  ComponentVersionsQuery,
  ComponentWithVersionsDetailQuery,
} from '@app/models';
import { ScanComponentService } from '@app/services/scan-component.service';
import {
  DefaultVulnerabilitiesSeveretyTotal,
  vulnerabilitiesSortOrder,
} from '@app/shared/module-components/component-detail-new/store/component-detail.store';
import { ApolloQueryResult } from 'apollo-client';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-component-versions-table',
  templateUrl: './component-versions-table.component.html',
  styleUrls: ['./component-versions-table.component.scss'],
})
export class ComponentVersionsTableComponent implements OnInit {
  @Input() public component: ComponentWithVersionsDetailQuery;

  public componentVersionsLoading: boolean = false;
  public constructor(private scanComponentService: ScanComponentService) {}

  public ngOnInit(): void {
    this.component = JSON.parse(JSON.stringify(this.component));
    this.component.component['maxSeverity'] = this.getMaxSeverety(
      this.component.component['vulnerabilities']
    );

    this.component.component.componentVersions.resultList.forEach(
      (componentVersion) => {
        componentVersion.vulnerabilities.maxSeverity = this.getMaxSeverety(
          componentVersion.vulnerabilities
        );
        componentVersion.vulnerabilitiesSeveretyTotal = {
          ...DefaultVulnerabilitiesSeveretyTotal,
        };
        componentVersion.vulnerabilities.edges.forEach((element) => {
          componentVersion.vulnerabilitiesSeveretyTotal[
            element.node.severity
          ] += 1;
        });
      }
    );
  }

  public onScrollDown(): void {
    const start: number =
      this.component.component.componentVersions.start +
      this.component.component.componentVersions.pageSize;
    if (
      start > this.component.component.componentVersions.total ||
      this.componentVersionsLoading
    ) {
      return;
    }
    this.componentVersionsLoading = true;
    this.scanComponentService
      .getComponentVersions(
        this.component.purl,
        this.component.component.componentVersions.pageSize,
        start
      )
      .pipe(
        map(
          (result: ApolloQueryResult<ComponentVersionsQuery>) =>
            result.data.componentVersions
        )
      )
      .subscribe((res: ComponentVersions) => {
        this.componentVersionsLoading = false;
        this.component.component.componentVersions.pageSize = res.pageSize;
        this.component.component.componentVersions.total = res.total;
        this.component.component.componentVersions.start = res.start;

        const updatedResultList = res.resultList.map((componentVersion) => {
          const maxSeverity = this.getMaxSeverety(
            componentVersion.vulnerabilities
          );

          const vulnerabilitiesSeveretyTotal = {
            ...DefaultVulnerabilitiesSeveretyTotal,
          };

          componentVersion.vulnerabilities.edges.forEach((element) => {
            vulnerabilitiesSeveretyTotal[element.node.severity] += 1;
          });

          return {
            ...componentVersion,
            vulnerabilitiesSeveretyTotal,
            maxSeverity,
          };
        });

        this.component.component.componentVersions.resultList = [
          ...this.component.component.componentVersions.resultList,
          ...updatedResultList,
        ];
      });
  }

  private getMaxSeverety(vulnerabilities: {
    edges: Array<{
      node: {
        vulnerabilityAlias: string;
        severity: string;
      };
    }>;
  }): string {
    if (vulnerabilities.edges.length === 0) {
      return null;
    }
    if (vulnerabilities.edges.length === 1) {
      return vulnerabilities.edges[0].node.severity;
    }

    const severities = vulnerabilities.edges.map((edge) => edge.node.severity);
    let index: number;
    severities.forEach((item: string) => {
      if (index === undefined) {
        index = vulnerabilitiesSortOrder.indexOf(item);
      } else {
        const itIndex = vulnerabilitiesSortOrder.indexOf(item);
        index = index < itIndex ? index : itIndex;
      }
    });
    return vulnerabilitiesSortOrder[index];
  }
}
