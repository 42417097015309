import { LicenseQueryNode } from './license';
import { ScanComponent } from './scan-asset';

export class FixResult {
  buildFile: string;
  success: boolean;
  errorMessage: string;
}

export class TxComponent {
  componentId: string;
  name: string;
  group: string;
  version: string;
  componentType: string;
  copyrightList?: Copyright[];
  releaseDate: Date;
  component?: any;
  dependencyManagerType?: string;
  vulnerabilities?: any;
}

export class Copyright {
  text: string;
  startYear: number;
  endYear: number;
  owners: string[];
  toPresent: boolean;
}

export interface ComponentQuery {
  component: TxComponent;
}
export interface ScanComponentQuery {
  scanComponent: ComponentDetailQuery;
}

export interface FixComponentVersionQuery {
  fixComponentVersion: FixResult[];
}

export interface ComponentDetailQuery {
  component: TxComponent;
  componentId: string;
  group: string;
  licenses: LicenseQueryNode;
  name: string;
  releaseDate: string;
  version: string;
  purl?: string;
}

export interface ScanComponentWithVersionsQuery {
  scanComponent: ComponentWithVersionsDetailQuery;
}

export interface ComponentWithVersionsDetailQuery {
  component: ComponentWithVersions;
  componentId: string;
  componentType: string;
  group: string;
  licenses: LicenseQueryNode;
  name: string;
  releaseDate: string;
  version: string;
  purl?: string;
}

export interface ComponentWithVersions extends TxComponent {
  componentVersions: ComponentVersions;
  errorData?: boolean;
}

export interface ComponentVersionsQuery {
  componentVersions: ComponentVersions;
}

export interface ComponentVersions {
  resultList: Array<ComponentVersionsResult>;
  pageSize: number;
  start: number;
  total: number;
  currentIndex?: number;
}

export interface ComponentVersionsResult {
  componentId: string;
  purl: string;
  version: string;
  releaseDate: Date;
  licenses: {
    edges: Array<{
      node: {
        name: string;
        licenseId: string;
      };
    }>;
  };
  vulnerabilities: {
    edges: Array<{
      node: {
        vulnerabilityAlias: string;
        severity: string;
      };
    }>;
    maxSeverity?: string;
  };
  changeIcon?: string;
  vulnerabilitiesSeveretyTotal?: {
    CRITICAL: number;
    HIGH: number;
    MEDIUM: number;
    LOW: number;
    INFO: number;
    UNASSIGNED: number;
  };
}

export const PurlTypes = [
  { id: 'none', name: '- Any Ecosystem -' },
  { id: 'maven', name: 'MAVEN' },
  { id: 'npm', name: 'NPM' },
  { id: 'nuget', name: 'NUGET' },
  { id: 'pypi', name: 'PYPI' },
  { id: 'gem', name: 'GEM' },
  { id: 'composer', name: 'COMPOSER' },
  { id: 'conan', name: 'CONAN' },
  { id: 'golang', name: 'GOLANG' },
  { id: 'cocoapods', name: 'COCOAPODS' },
  { id: 'luarocks', name: 'LUAROCKS' },
  { id: 'bazel', name: 'BAZEL' },
  { id: 'hex', name: 'HEX' },
  { id: 'cargo', name: 'CARGO' },
  { id: 'cran', name: 'CRAN' },
  { id: 'github', name: 'GITHUB' },
  { id: 'gitlab', name: 'GITLAB' },
  { id: 'bitbucket', name: 'BITBUCKET' },
  { id: 'alpm', name: 'ALPM' },
  { id: 'apk', name: 'APK' },
  { id: 'bitnami', name: 'BITNAMI' },
  { id: 'conda', name: 'CONDA' },
  { id: 'deb', name: 'DEB' },
  { id: 'docker', name: 'DOCKER' },
  { id: 'generic', name: 'GENERIC' },
  { id: 'hackage', name: 'HACKAGE' },
  { id: 'mlflow', name: 'MLFLOW' },
  { id: 'oci', name: 'OCI' },
  { id: 'pub', name: 'PUB' },
  { id: 'qpkg', name: 'QPKG' },
  { id: 'rpm', name: 'RPM' },
  { id: 'swid', name: 'SWID' },
  { id: 'swift', name: 'SWIFT' },
  { id: 'unknown', name: 'UNKNOWN' },
];
