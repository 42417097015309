import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { CoreGraphQLService } from './core';
import { GraphQLPage } from '@app/models/paging';

export interface ReviewAllComponentsData {
  entityId: string;
  scanId: string;
  assetPath: string;
  filter?: string;
}

@Injectable()
export class ComponentsService {
  public constructor(private coreGraphQLService: CoreGraphQLService) {}

  public reviewAllByComponents(
    reviewData: ReviewAllComponentsData,
    type: 'approve' | 'reject' | 'ignore' | 'revert'
  ) {
    switch (type) {
      case 'approve':
        return this.approveAllByComponents(reviewData);
      case 'reject':
        return this.rejectAllByComponents(reviewData);
      case 'ignore':
        return this.ignoreAllByComponents(reviewData);
      default:
        break;
    }
  }

  public reviewAllByList(
    reviewData: ReviewAllComponentsData,
    type: 'approve' | 'reject' | 'ignore' | 'revert'
  ) {
    switch (type) {
      case 'approve':
        return this.approveAllByList(reviewData);
      case 'reject':
        return this.rejectAllByList(reviewData);
      case 'ignore':
        return this.ignoreAllByList(reviewData);
      default:
        break;
    }
  }

  public approveAllByComponents(reviewData: ReviewAllComponentsData) {
    let params: string = reviewData.scanId
      ? `, scanId: "${reviewData.scanId}"`
      : '';
    params += reviewData.assetPath
      ? `, assetPath: "${reviewData.assetPath}"`
      : '';
    const filterParams: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            mutation {
              approveAllByComponents(entityId: "${reviewData.entityId}"${params} ${filterParams})
            }`,
      'no-cache'
    );
  }

  public rejectAllByComponents(reviewData: ReviewAllComponentsData) {
    let params: string = reviewData.scanId
      ? `, scanId: "${reviewData.scanId}"`
      : '';
    params += reviewData.assetPath
      ? `, assetPath: "${reviewData.assetPath}"`
      : '';
    const filterParams: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            mutation {
              rejectAllByComponents(entityId: "${reviewData.entityId}"${params} ${filterParams})
            }`,
      'no-cache'
    );
  }

  public ignoreAllByComponents(reviewData: ReviewAllComponentsData) {
    let params: string = reviewData.scanId
      ? `, scanId: "${reviewData.scanId}"`
      : '';
    params += reviewData.assetPath
      ? `, assetPath: "${reviewData.assetPath}"`
      : '';
    const filterParams: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            mutation {
              ignoreAllByComponents(entityId: "${reviewData.entityId}"${params} ${filterParams})
            }`,
      'no-cache'
    );
  }

  public approveAllByList(reviewData: ReviewAllComponentsData) {
    let params: string = reviewData.scanId
      ? `, scanId: "${reviewData.scanId}"`
      : '';
    params += reviewData.assetPath
      ? `, assetPath: "${reviewData.assetPath}"`
      : '';
    const filterParams: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            mutation {
                approveAllByPath(entityId: "${reviewData.entityId}"${params} ${filterParams})
            }`,
      'no-cache'
    );
  }

  public rejectAllByList(reviewData: ReviewAllComponentsData) {
    let params: string = reviewData.scanId
      ? `, scanId: "${reviewData.scanId}"`
      : '';
    params += reviewData.assetPath
      ? `, assetPath: "${reviewData.assetPath}"`
      : '';
    const filterParams: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            mutation {
                rejectAllByPath(entityId: "${reviewData.entityId}"${params} ${filterParams})
            }`,
      'no-cache'
    );
  }

  public ignoreAllByList(reviewData: ReviewAllComponentsData) {
    let params: string = reviewData.scanId
      ? `, scanId: "${reviewData.scanId}"`
      : '';
    params += reviewData.assetPath
      ? `, assetPath: "${reviewData.assetPath}"`
      : '';
    const filterParams: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            mutation {
                ignoreAllByPath(entityId: "${reviewData.entityId}"${params} ${filterParams})
            }`,
      'no-cache'
    );
  }

  public reviewAllComponents(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation updateScpComponentStatuses(
          $statusesUpdateRequest: ScpComponentStatusesUpdateRequestInput
        ) {
          updateScpComponentStatuses(
            statusesUpdateRequest: $statusesUpdateRequest
          ) {
            scanId
            componentPurl
          }
        }
      `,
      { statusesUpdateRequest: request }
    );
  }

  public revertAllComponents(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation revertScanComponentStatuses(
          $components: [ScpComponentToBeUpdatedDTOInput]
        ) {
          revertScanComponentStatuses(components: $components) {
            scanId
            componentPurl
          }
        }
      `,
      { components: request }
    );
  }

  public componentListForPath2(
    entityId: string,
    scanId: string,
    assetPath: string,
    filters: string,
    graphQLPage: GraphQLPage
  ) {
    let params: string = scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    params += `, first: ${graphQLPage.first}`;
    params += graphQLPage.after ? `, after: "${graphQLPage.after}"` : '';

    if (filters) {
      params += `, filters: "${filters}"`;
    }

    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
        componentListForPath(entityId: "${entityId}"${params}) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          },
          edges {
            node {
              scanId,
              componentId,
              projectPath
              name,
              group,
              version,
              orgId,
              purl,
              releaseDate,
              isInternal,
              componentType,
              componentLocation,
              componentDiscoveryMethod,
              rejected,
              reviewed,
              ignored,
              isInternal,
              auditorRecommends,
              auditorFullName,
              dependencyManagerType,
              auditDate,
              vulnLinkCorrect,
              orgComponentId,
              workspaceRelativeFilePath,
              policyViolationNotation {
                policyId
                policyName
              }
              licenses {
                edges {
                  node {
                    licenseId
                    name
                    category
                    isOrgLicense
                    orgId
                    rejected
                    
                    license {
                      licenseId
                          name
                          spdxId
                          body
                          description
                          notes
                          
                          category
                          style
                          publicationYear
                          type
                          compatible
                          incompatible
                          isOrgLicense
                          customerLicenseId
                          attributes {
                              name
                              type
                              description
                          }
                    }
                  }
                }
              }
              
              
              allVulnerabilities {
                vulnerabilityId
                vulnerabilityAlias
                severity
                patchedVersions
                cwe {
                  cweId
                  name
                }
                epss {
                  date
                  epssPercent
                  percentilePercent
                }
                cvssV2BaseScore
                cvssV3BaseScore
                published
                source
              }
              
              
              scanComponentCopyrights {
                text
                rejected
              }
            }
          }
        }
      }`,
      'no-cache'
    );
  }

  public componentMetricsForPath(
    entityId: string,
    scanId: string,
    assetPath: string
  ) {
    let params: string = scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          componentMetricsForPath(entityId: "${entityId}"${params})
        }`,
      'no-cache'
    );
  }

  public getComponentReleasesPageURL(purl: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          componentReleasesPageURL(purl: "${purl}")
        }`,
      'no-cache'
    );
  }

  public saveCustomComponent(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation CreateOrgComponent(
          $copyright: String
          $licenseIds: [String]
          $name: String
          $namespace: String
          $purlType: String
          $releaseDate: String
          $scanAssetId: String
          $scanId: UUID
          $version: String
          $isInternal: Boolean!
        ) {
          createOrgComponent(
            copyright: $copyright
            licenseIds: $licenseIds
            name: $name
            namespace: $namespace
            purlType: $purlType
            releaseDate: $releaseDate
            scanAssetId: $scanAssetId
            scanId: $scanId
            version: $version
            isInternal: $isInternal
          ) {
            name
            purl
          }
        }
      `,
      {
        copyright: request.copyright,
        licenseIds: request.licenseIds,
        name: request.name,
        namespace: request.namespace,
        purlType: request.purlType,
        releaseDate: request.releaseDate,
        scanAssetId: request.scanAssetId,
        scanId: request.scanId,
        version: request.version,
        isInternal: request.isInternal,
      }
    );
  }

  public overrideOrgComponent(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation OverrideComponent(
          $copyright: String
          $licenseIds: [String]
          $name: String
          $namespace: String
          $oldPurl: String
          $purlType: String
          $releaseDate: String
          $scanAssetId: String
          $scanId: UUID
          $version: String
          $isInternal: Boolean!
          $filePath: String
        ) {
          overrideComponent(
            copyright: $copyright
            licenseIds: $licenseIds
            name: $name
            namespace: $namespace
            oldPurl: $oldPurl
            purlType: $purlType
            releaseDate: $releaseDate
            scanAssetId: $scanAssetId
            scanId: $scanId
            version: $version
            isInternal: $isInternal
            filePath: $filePath
          ) {
            name
            purl
          }
        }
      `,
      {
        copyright: request.copyright,
        licenseIds: request.licenseIds,
        name: request.name,
        namespace: request.namespace,
        oldPurl: request.oldPurl,
        purlType: request.purlType,
        releaseDate: request.releaseDate,
        scanAssetId: request.scanAssetId,
        scanId: request.scanId,
        version: request.version,
        isInternal: request.isInternal,
        filePath: request.filePath,
      }
    );
  }

  public getOrgComponents() {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          orgComponents {
            isInternal
            licenses {
              category
              name
              licenseId
            }
            orgId
            purl
            releaseDate
            copyright
            orgComponentId
          }
        }
      `,
      'no-cache'
    );
  }

  public kbComponents(
    name: string,
    namespace: string,
    type: string = '',
    pageable: any = {
      page: 0,
      size: 100,
    }
  ) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query KbComponents(
          $name: String
          $namespace: String
          $pageable: GraphQLJPAPageableInput
          $type: String
        ) {
          kbComponents(name: $name, namespace: $namespace, pageable: $pageable, type: $type) {
            content {
              purlHashCode
              purl
              schema
              type
              namespace
              name
              description
              sourceRepositoryPurl
              trusted
              web_address
            }
            pageInfo {
              pageNumber
              pageSize
              totalPages
              totalElements
            }
          }
        }
      `,
      'no-cache',
      {
        name,
        namespace,
        pageable: {
          size: pageable.size,
          page: pageable.page,
        },
        type,
      },
    );
  }

  public kbComponentReleaseList(purl: string) {
    return this.coreGraphQLService.coreGQLReq<{
      kbComponentReleaseList: Array<string>;
    }>(
      gql`
        query {
          kbComponentReleaseList(purl: "${purl}") 
        }
      `,
      'no-cache'
    );
  }

  public kbComponentRelease(purl: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          kbComponentRelease(purl: "${purl}") 
          {
            componentId
            name
            group
            version
            isInternal
            description
            purl
            releaseDate
            
            copyrightList {
              text
            }

            licenses {
              edges {
                node {
                  licenseId
                  name
                  category
                }
              }
            }
          }
        }
      `,
      'no-cache'
    );
  }

  public editOrgComponent(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation EditOrgComponent(
          $copyright: String
          $licenseIds: [String]
          $name: String
          $namespace: String
          $purlType: String
          $releaseDate: String
          $version: String
          $isInternal: Boolean!
          $orgComponentId: UUID
        ) {
          editOrgComponent(
            copyright: $copyright
            licenseIds: $licenseIds
            name: $name
            namespace: $namespace
            purlType: $purlType
            releaseDate: $releaseDate
            version: $version
            isInternal: $isInternal
            orgComponentId: $orgComponentId
          ) {
            purl
          }
        }
      `,
      {
        copyright: request.copyright,
        licenseIds: request.licenseIds,
        name: request.name,
        namespace: request.namespace,
        purlType: request.purlType,
        releaseDate: request.releaseDate,
        originalScanAssetId: request.originalScanAssetId,
        version: request.version,
        isInternal: request.isInternal,
        orgComponentId: request.orgComponentId,
      }
    );
  }

  public deleteOrgComponent(orgComponentId: string) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation DeleteOrgComponent($orgComponentId: UUID) {
          deleteOrgComponent(orgComponentId: $orgComponentId)
        }
      `,
      { orgComponentId }
    );
  }
}
