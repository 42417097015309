import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  License,
  LicenseCategories,
  LicenseCategoryPair,
  PurlTypes,
} from '@app/models';
import { EMPTY, Observable, OperatorFunction, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { LicenseService } from '@app/services/license.service';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { DatePipe } from '@angular/common';
import { NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';
import { ComponentsService } from '@app/services/components.service';
import { AlertService } from '@app/services/core';

@Component({
  selector: 'app-component-add-dialog',
  templateUrl: './component-add-dialog.component.html',
  styleUrls: ['./component-add-dialog.component.scss'],
  providers: [DatePipe, PurlParserPipe],
})
export class ComponentAddDialogComponent implements AfterViewInit {
  public componentToAdd = {
    licenses: [],
    purlType: 'none',
    namespace: '',
    name: '',
    version: '',
    copyright: '',
    releaseDate: null,
    isInternal: false,
    scanAssetId: '',
    scanId: '',
    orgComponentId: '',
    purl: '',
    filePath: '',
  };
  public minDate: NgbDateStruct = { year: 1990, month: 1, day: 1 }; // Minimum year
  public maxDate: NgbDateStruct = { year: 2100, month: 12, day: 31 }; // Maximum year

  public editing: boolean = false;
  public overriding: boolean = false;
  public isComponentLoading: boolean = false;
  public hasComponentLoadingError: boolean = false;

  public versionList: Array<string> = [];
  public componentList: Array<KBComponent> = [];
  public isNoComponentFound: boolean = false;
  public componentPage: number = 0;
  public componentHasMorePages: boolean = false;

  public lookupName: string = '';
  public lookupType: string = 'none';
  public lookupNamespace: string = '';

  public licenseToAdd: string = '';

  @ViewChild('versionModel') versionModel!: NgModel;
  @ViewChild('nameModel') nameModel!: NgModel;
  @ViewChild('namespaceModel') namespaceModel!: NgModel;
  @ViewChild('dateModel') dateModel!: NgModel;
  @ViewChild('purlTypeModel') purlTypeModel!: NgModel;

  @ViewChild('compDropdown') compDropdown!: NgbDropdown;

  public purTypes = PurlTypes;

  @Output() public componentData = new EventEmitter();

  // operational
  public categories: LicenseCategoryPair[] = LicenseCategories.values();

  @ViewChild(UiModalComponent)
  modal: UiModalComponent;

  public constructor(
    private licenseService: LicenseService,
    private datePipe: DatePipe,
    private purlParser: PurlParserPipe,
    private componentsService: ComponentsService,
    private alertService: AlertService
  ) {}

  ngAfterViewInit() {
    this.nameModel.valueChanges.subscribe(value => {
      if (this.isNoComponentFound) {
        this.isNoComponentFound = false;
      }
    });
  }

  public openDialog(componentData?: any, type?: 'edit' | 'override'): void {
    this.editing = !!componentData;
    this.overriding = type === 'override';

    this.componentToAdd = componentData
      ? this.mapComponentData(componentData)
      : this.getDefaultComponent();

    this.lookupNamespace = '';
    this.lookupName = '';
    this.lookupType = 'none';
    this.licenseToAdd = '';
    this.modal.show();
  }

  public saveComponent(): void {
    if (this.validateAllFields()) {
      return;
    }

    if (this.componentToAdd.releaseDate?.year) {
      this.formatDate(this.componentToAdd.releaseDate);
    }

    const requestComponentData = {
      copyright: this.componentToAdd.copyright,
      licenseIds: this.componentToAdd.licenses.map(
        (license) => license.licenseId
      ),
      name: this.componentToAdd.name,
      namespace: this.componentToAdd.namespace,
      oldPurl: this.componentToAdd.purl,
      purlType: this.componentToAdd.purlType,
      releaseDate: this.componentToAdd.releaseDate,
      version: this.componentToAdd.version,
      isInternal: this.componentToAdd.isInternal,
      modal: this.modal,
      orgComponentId: this.componentToAdd.orgComponentId,
      editing: this.editing,
      overriding: this.overriding,
      filePath: this.componentToAdd.filePath,
    };
    this.componentData.emit(requestComponentData);
  }

  public formatDate(date: NgbDateStruct): void {
    if (date) {
      // Convert NgbDateStruct to JavaScript Date
      const jsDate = new Date(date.year, date.month - 1, date.day); // month is 0-indexed
      // Format the JavaScript Date to dd-MM-yyyy
      this.componentToAdd.releaseDate =
        this.datePipe.transform(jsDate, 'dd-MM-yyyy') || '';
    }
  }

  public toNgbDateStruct(dateString: string): NgbDateStruct | null {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      // Return null if the input is not a valid date
      return null;
    }

    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1, // Months are 0-based in JavaScript
      day: date.getUTCDate(),
    };
  }

  public close(): void {
    this.modal.hide();
  }

  public onFindComponentClick(): void {
    this.versionList = [];
    this.componentList = [];
    this.isComponentLoading = true;
    this.isNoComponentFound = false;
    this.componentPage = 0;
    this.findComponent();
  }

  private findComponent(): void {
    this.hasComponentLoadingError = false;
    this.componentsService.kbComponents(
      this.lookupName,
      this.lookupNamespace,
      this.lookupType === 'none'
        ? ''
        : this.lookupType,
      {
        page: this.componentPage,
        size: 100,
      }
    )
    .pipe(
      take(1),
      finalize(() => {
        this.isComponentLoading = false;
      }),
      catchError(() => {
        this.hasComponentLoadingError = true;
        return EMPTY;
      })
    )
      .subscribe((data) => {
        const result = data.data.kbComponents;
        this.componentList = [...this.componentList, ...result.content];
        if (result.pageInfo.totalPages - 1 === result.pageInfo.pageNumber) {
          this.componentHasMorePages = false;
        } else {
          this.componentHasMorePages = true;
        }

        if (this.componentList.length === 0) {
          this.isNoComponentFound = true;
        } else {
          this.compDropdown.open();
        }
      });
  }

  public loadMoreComponents(): void {
    if (this.componentHasMorePages) {
      this.componentPage = this.componentPage + 1;
      this.findComponent();
    }
  }

  public trackByFn(index: number, item: KBComponent): number {
    return item.purlHashCode;
  }

  public onComponentSelect(component: KBComponent): void {
    this.componentToAdd.name = component.name;
    this.componentToAdd.namespace = component.namespace;
    this.componentToAdd.purlType = component.type;
    this.componentToAdd.purl = component.purl;
    this.setVersionList(component.purl);
  }

  formatter = (license: License) => license.name;

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.licenseService
          .getLicenseByName(term)
          .pipe(
            map((data) =>
              data.data.licensesByNameFilter
                .filter(
                  (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
                )
                .slice(0, 20)
            )
          )
      )
    );

  versionSearch: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((version) =>
        this.versionList
          .filter((v) =>
            version ? v.toLowerCase().includes(version.toLowerCase()) : true
          )
          .slice(0, 20)
      )
    );

  onVersionInputFocus(versionInput: HTMLInputElement) {
    versionInput.dispatchEvent(new Event('input', { bubbles: true }));
  }

  public setLicenseProperties(event) {
    this.componentToAdd.licenses.push(event.item);
  }

  public setComponentVersion(event) {
    if (this.versionList.includes(event.item)) {
      this.componentsService
        .kbComponentRelease(
          `${this.componentToAdd.purl.replace(/@[^@]*$/, '')}@${event.item}`
        )
        .pipe(take(1))
        .subscribe((data) => {
          const componentRelease = data.data.kbComponentRelease;
          if (componentRelease) {
            this.componentToAdd.licenses = componentRelease.licenses.edges.map(
              (license) => license.node
            );

            this.componentToAdd.copyright = componentRelease.copyrightList
              .map((copyright) => copyright.text)
              .join('\n');
            if (componentRelease.releaseDate) {
              this.componentToAdd.releaseDate = this.toNgbDateStruct(
                componentRelease.releaseDate
              );
            }
          } else {
            this.alertService.alertBox(
              'No data found for selected version, please fill information manually',
              'Add Component',
              'Info'
            );
          }
        });
    }
  }

  public deleteLicense(id: string): void {
    this.componentToAdd.licenses = this.componentToAdd.licenses.filter(
      (license) => license.licenseId !== id
    );
  }

  private setVersionList(purl: string, isExisting?: boolean): void {
    if (isExisting) {
      purl = purl.replace(/@[^@]*$/, '');
    }
    this.componentsService
      .kbComponentReleaseList(purl)
      .pipe(take(1))
      .subscribe((data) => {
        if (
          data.data.kbComponentReleaseList &&
          data.data.kbComponentReleaseList.length > 0
        ) {
          this.versionList = data.data.kbComponentReleaseList;
        } else if (!isExisting) {
          this.alertService.alertBox(
            'No releases found for selected component, please fill information manually',
            'Add Component',
            'Info'
          );
        }
      });
  }

  private resetFields(): void {
    const controls = [
      this.versionModel,
      this.nameModel,
      this.namespaceModel,
      this.dateModel,
      this.purlTypeModel,
    ];
    this.versionList = [];
    controls.forEach((control) => {
      if (control && control.control) {
        control.control.markAsPristine(); // Mark as pristine
        control.control.markAsUntouched(); // Mark as untouched
        control.control.updateValueAndValidity(); // Re-evaluate validation state
      }
    });
  }

  private mapComponentData(componentData: any): any {
    this.setVersionList(componentData.purl, true);
    return {
      licenses: componentData.licenses?.edges
        ? componentData.licenses.edges.map((license) => license.node)
        : componentData.licenses || [],

      purlType:
        componentData.purlType ??
        this.purlParser.transform(componentData.purl, 'purlType'),

      namespace:
        componentData.namespace ??
        this.purlParser.transform(componentData.purl, 'namespace'),

      name: componentData.name || '',
      version: componentData.version || '',
      copyright:
        componentData.copyright ??
        componentData.scanComponentCopyrights?.[0]?.text ??
        '',

      releaseDate: this.toNgbDateStruct(componentData.releaseDate),
      isInternal: !!componentData.isInternal,
      scanAssetId: '',
      scanId: '',
      orgComponentId: componentData.orgComponentId || '',
      purl: componentData.purl || '',
      filePath: componentData.workspaceRelativeFilePath,
    };
  }

  private getDefaultComponent(): any {
    this.resetFields();
    return {
      licenses: [],
      purlType: 'none',
      namespace: '',
      name: '',
      version: '',
      copyright: '',
      releaseDate: null,
      isInternal: false,
      scanAssetId: '',
      scanId: '',
      orgComponentId: '',
      filePath: '',
    };
  }

  private validateAllFields(): boolean {
    const missingFields: string[] = [];
    const spaceNotAllowedMessage = 'Component Name cannot contain spaces';

    // Check if name is missing or contains spaces
    if (!this.componentToAdd.name) {
      this.nameModel.control.markAsTouched();
      missingFields.push('Component Name');
    } else if (/\s/.test(this.componentToAdd.name)) {
      this.nameModel.control.markAsTouched();
      missingFields.push(spaceNotAllowedMessage);
    }

    if (!this.componentToAdd.version) {
      this.versionModel.control.markAsTouched();
      missingFields.push('Component Version');
    }

    // if (!this.componentToAdd.namespace) {
    //   this.namespaceModel.control.markAsTouched();
    //   missingFields.push('Component Namespace');
    // }

    if (!this.componentToAdd.purlType) {
      this.purlTypeModel.control.markAsTouched();
      missingFields.push('PURL Type');
    }
    if (missingFields.length > 0) {
      return true;
    }

    return false;
  }
}

import { Injectable } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PurlParserPipe } from '@app/shared/pipes/purl-parser.pipe';
import { KBComponent } from '@app/models/kb.models';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  private readonly DELIMITER = '-';

  // Parse the string to NgbDateStruct
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const parts = value.split(this.DELIMITER);
      return {
        day: parseInt(parts[0], 10),
        month: parseInt(parts[1], 10),
        year: parseInt(parts[2], 10),
      };
    }
    return null;
  }

  // Format the NgbDateStruct to string
  format(date: NgbDateStruct | null): string {
    return date
      ? `${this.pad(date.day)}${this.DELIMITER}${this.pad(date.month)}${
          this.DELIMITER
        }${date.year}`
      : '';
  }

  // Pad single-digit numbers with a leading zero
  private pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
