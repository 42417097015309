import {
  ApexDataLabels,
  ApexNoData,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexStroke,
  ApexTooltip,
} from 'ng-apexcharts';

export class ChartData {
  title: string;

  labels: string[];
  series: ApexNonAxisChartSeries;
  colors: string[];

  fill: {
    colors: string[];
  };

  chartLabels: {
    label: string;
    class: string;
  }[];

  constructor(
    title?: string,
    series?: ApexNonAxisChartSeries,
    labels?: string[],
    colors?: string[]
  ) {
    this.title = title;
    this.series = series;
    this.labels = labels;
    this.colors = colors;
  }
}

export interface DonutChartConfiguration {
  stroke: ApexStroke;
  labels: Array<string>;
  series: Array<any>;
  colors: Array<string>;
  noData: ApexNoData & { style: {
    color: string;
    fontSize: string;
    fontFamily: string;
    fontWeight: string;
  };};
  dataLabels: ApexDataLabels;
  legend: {
    show: false;
  };
  tooltip: ApexTooltip;
  chart: any;
  responsive: ApexResponsive[];
  plotOptions: ApexPlotOptions;
}
