import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import {
  ComponentVersionsQuery,
  ScanComponentQuery,
  ScanComponentWithVersionsQuery,
} from '@app/models';
import gql from 'graphql-tag';

@Injectable()
export class ScanComponentService {
  constructor(private coreGraphQLService: CoreGraphQLService) {}

  getScanComponent(
    scanId,
    componentId,
    projectId,
    isComposite,
    first = undefined,
    last = undefined,
    after: string = undefined,
    before: string = undefined
  ) {
    const firstArg = !!first ? `first: ${first}` : '';
    const lastArg = !!last ? `last: ${last}` : '';
    const afterArg = after ? `, after: "${after}"` : '';
    const beforeArg = before ? `, before: "${before}"` : '';
    return this.coreGraphQLService.coreGQLReq<ScanComponentQuery>(gql`
        query {
            scanComponent(scanId: "${scanId}", componentPurl: "${componentId}", projectId: "${projectId}", isComposite: ${isComposite}) {
                componentId
                name
                group
                version
                releaseDate
                supplyChainRiskScore
                componentType
                purl
                component {
                    componentId,
                    name,
                    group,
                    version,
                    isInternal,
                    description,
                    purl,
                    copyrightList {
                        text
                    }
                    vulnerabilities(${firstArg}${lastArg}${afterArg}${beforeArg}) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        totalCount
                        edges {
                            node {
                                vulnerabilityId,
                                vulnerabilityAlias,
                                source,
                                recommendation,
                                vulnerableVersions,
                                patchedVersions
                                published,
                                cwe {
                                    cweId,
                                    name
                                },
                                epss {
                                  epssPercent
                                  date
                                  percentilePercent
                                },
                                cvssV2BaseScore,
                                cvssV3BaseScore,
                                severity
                            }
                        }
                    }
                    metrics {
                        critical,
                        high,
                        medium,
                        low,
                        unassigned,
                        vulnerabilities,
                        suppressed,
                        findingsTotal,
                        findingsAudited,
                        findingsUnaudited,
                        inheritedRiskScore,
                        firstOccurrence,
                        lastOccurrence
                    }
                },
                licenses {
                    edges {
                        node {
                            licenseId,
                            name,
                            category,
                            style,
                            type,
                            spdxId,
                            publicationYear,
                            licenseDiscovery,
                            licenseOrigin,
                            isOsiApproved,
                            isFsfLibre,
                            trustLevel
                        }
                    }
                }
            }
        }
        `);
  } // getScanComponent

  getScanComponentWithVersions(
    scanId,
    componentId,
    projectId,
    isComposite,
    componentVersionsPageSize: number
  ) {
    return this.coreGraphQLService
      .coreGQLReq<ScanComponentWithVersionsQuery>(gql`
        query {
            scanComponent(scanId: "${scanId}", componentPurl: "${componentId}", projectId: "${projectId}", isComposite: ${isComposite}) {
                componentId
                name
                group
                version
                releaseDate
                supplyChainRiskScore
                componentType
                purl
                scanId
                auditorRecommends
                component {
                    componentId,
                    name,
                    group,
                    version,
                    isInternal,
                    description,
                    purl,
                    copyrightList {
                        text
                    }
                    vulnerabilities {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        totalCount
                        edges {
                          node {
                            vulnerabilityId,
                            vulnerabilityAlias,
                            vulnerableVersions,
                            patchedVersions
                            published,
                            cwe {
                              cweId,
                              name
                            },
                            epss {
                              epssPercent
                              date
                              percentilePercent
                            },
                            cvssV2BaseScore,
                            cvssV3BaseScore,
                            severity
                          }
                        }
                    }
                  componentVersions(pageSize: ${componentVersionsPageSize}, start: 0) {
                    pageSize
                    start
                    total
                    resultList {
                      componentId
                      purl
                      version
                      releaseDate
                      licenses {
                        edges {
                          node {
                            licenseId
                            name
                          }
                        }
                      }
                      vulnerabilities {
                        edges {
                          node {
                            severity
                            vulnerabilityAlias
                          }
                        }
                      }
                    }
                  }
                }
                licenses {
                    edges {
                        node {
                            licenseId,
                            name,
                            category,
                            style,
                            type,
                            spdxId,
                            publicationYear,
                            licenseDiscovery,
                            licenseOrigin,
                            isOsiApproved,
                            isFsfLibre,
                            trustLevel
                        }
                    }
                }
            }
        }
        `);
  } // getScanComponentWithVersions

  // the same as getScanComponentWithVersions but uses allVulns instead of vulnerabilities
  getScanComponentWithAllVulnerabilities(
    scanId,
    componentId,
    projectId,
    isComposite,
    componentVersionsPageSize: number
  ) {
    return this.coreGraphQLService
      .coreGQLReq<ScanComponentWithVersionsQuery>(gql`
        query {
            scanComponent(scanId: "${scanId}", componentPurl: "${componentId}", projectId: "${projectId}", isComposite: ${isComposite}) {
                componentId
                name
                group
                version
                releaseDate
                supplyChainRiskScore
                componentType
                purl
                scanId
                auditorRecommends
                component {
                    componentId,
                    name,
                    group,
                    version,
                    isInternal,
                    description,
                    purl,
                    copyrightList {
                        text
                    }
                    allVulns {
                      created
                      credits
                      cvssV2BaseScore
                      cvssV2ExploitabilitySubScore
                      cvssV2ImpactSubScore
                      cvssV2Vector
                      cvssV3BaseScore
                      cvssV3ExploitabilitySubScore
                      cvssV3ImpactSubScore
                      cvssV3Vector
                      cwe {
                        cweId
                        name
                      }
                      description
                      epss {
                        date
                        epss
                        epssPercent
                        percentile
                        percentilePercent
                      }
                      fixInfo {
                        fixedVersion
                        introducedVersion
                        lastAffectedVersion
                      }
                      patchedVersions
                      published
                      recommendation
                      references
                      severity
                      source
                      subtitle
                      title
                      updated
                      vulnerabilityAlias
                      vulnerabilityId
                      vulnerableVersions
                    }
                    vulnerabilities {
                        edges {
                          node {
                            vulnerabilityId,
                            vulnerabilityAlias,
                            vulnerableVersions,
                            patchedVersions
                            published,
                            cwe {
                              cweId,
                              name
                            },
                            epss {
                              epssPercent
                              date
                              percentilePercent
                            },
                            cvssV2BaseScore,
                            cvssV3BaseScore,
                            severity
                          }
                        }
                    }
                  componentVersions(pageSize: ${componentVersionsPageSize}, start: 0) {
                    pageSize
                    start
                    total
                    resultList {
                      componentId
                      purl
                      version
                      releaseDate
                      licenses {
                        edges {
                          node {
                            licenseId
                            name
                          }
                        }
                      }
                      vulnerabilities {
                        edges {
                          node {
                            severity
                            vulnerabilityAlias
                          }
                        }
                      }
                    }
                  }
                }
                licenses {
                    edges {
                        node {
                            licenseId,
                            name,
                            category,
                            style,
                            type,
                            spdxId,
                            publicationYear,
                            licenseDiscovery,
                            licenseOrigin,
                            isOsiApproved,
                            isFsfLibre,
                            trustLevel
                        }
                    }
                }
            }
        }
        `);
  }

  getComponentAutofixPullRequest(
    componentPurl: string,
    isComposite: boolean,
    projectId: string,
    scanId: string
  ) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          scanComponent(componentPurl: "${componentPurl}", isComposite: ${isComposite}, projectId: "${projectId}", scanId: "${scanId}") {
            autofixPullRequest
          }
        }
      `
    );
  }

  getComponentVersions(componentPurl: string, pageSize: number, start: number) {
    return this.coreGraphQLService.coreGQLReq<ComponentVersionsQuery>(
      gql`
       query {
        componentVersions(componentPurl: "${componentPurl}", pageSize: ${pageSize}, start: ${start}) {
          pageSize
          start
          total
          resultList {
            componentId
            purl
            version
            releaseDate
            licenses {
              edges {
                node {
                  licenseId
                  name
                }
              }
            }
            vulnerabilities {
              edges {
                node {
                  severity
                  vulnerabilityAlias
                }
              }
            }
          }
        }
    }
    `
    );
  }

  setRejectedComponent(scanId, componentPurl) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($scanId: UUID, $componentPurl: String) {
          setRejectedComponent(scanId: $scanId, componentPurl: $componentPurl) {
            scanId
            componentId
            reviewed
            rejected
            ignored
            auditorUsername
            auditorFullName
            auditDate
          }
        }
      `,
      { scanId, componentPurl }
    );
  }

  setIgnoredComponent(scanId, componentPurl) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($scanId: UUID, $componentPurl: String) {
          setIgnoredComponent(scanId: $scanId, componentPurl: $componentPurl) {
            scanId
            componentId
            reviewed
            rejected
            ignored
            auditorUsername
            auditorFullName
            auditDate
          }
        }
      `,
      { scanId, componentPurl }
    );
  }

  setReviewedComponent(scanId, componentPurl) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($scanId: UUID, $componentPurl: String) {
          setReviewedComponent(scanId: $scanId, componentPurl: $componentPurl) {
            scanId
            componentId
            reviewed
            rejected
            ignored
            auditorUsername
            auditorFullName
            auditDate
          }
        }
      `,
      { scanId, componentPurl }
    );
  }

  revertScanComponentStatus(scanId, componentPurl) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($scanId: UUID, $componentPurl: String) {
          revertScanComponentStatus(
            scanId: $scanId
            componentPurl: $componentPurl
          ) {
            scanId
            componentPurl
          }
        }
      `,
      { scanId, componentPurl }
    );
  }

  setAuditorRecommends(scanId, componentPurl, auditorRecommends) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation (
          $scanId: UUID
          $componentPurl: String
          $auditorRecommends: String
        ) {
          setAuditorRecommends(
            scanId: $scanId
            componentPurl: $componentPurl
            auditorRecommends: $auditorRecommends
          ) {
            scanId
            componentId
            auditorRecommends
          }
        }
      `,
      { scanId, componentPurl, auditorRecommends }
    );
  }

  setComponentAsInternal(scanId, componentPurl) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($scanId: UUID, $componentPurl: String) {
          setComponentAsInternal(
            scanId: $scanId
            componentPurl: $componentPurl
          ) {
            scanId
            componentId
            auditorRecommends
            isInternal
          }
        }
      `,
      { scanId, componentPurl }
    );
  }

  addLicenseToScanComponent(scanComponent, license) {
    const scanComponentInput = {
      purl: scanComponent.purl,
      orgId: scanComponent.orgId,
      scanId: scanComponent.scanId,
      group: scanComponent.group,
      name: scanComponent.name,
      version: scanComponent.version,
    };
    const licenseDataInput = license;
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation (
          $scanComponentInput: ScanComponentInput
          $licenseDataInput: LicenseDataInput
        ) {
          addLicenseToScanComponent(
            scanComponent: $scanComponentInput
            license: $licenseDataInput
          ) {
            licenseId
            name
            category
            isOrgLicense
            orgId
            rejected

            license {
              licenseId
              name
              spdxId
              body
              description
              notes

              category
              style
              publicationYear
              type
              compatible
              incompatible
              isOrgLicense
              attributes {
                name
                type
                description
              }
            }
          }
        }
      `,
      { scanComponentInput, licenseDataInput }
    );
  }

  public assignLicenseToScanComponents(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation ($request: ScopedLicenseComponentAssignmentRequestInput) {
          assignLicenseToScanComponents(request: $request) {
            licenseId
            name
            category
            isOrgLicense
            orgId
            rejected

            license {
              licenseId
              name
              spdxId
              body
              description
              notes
              customerLicenseId
              category
              style
              publicationYear
              type
              compatible
              incompatible
              isOrgLicense
              attributes {
                name
                type
                description
              }
            }
          }
        }
      `,
      { request }
    );
  }

  public assignLicenseToScanAssetMatches(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation ($request: ScopedLicenseScanAssetMatchAssignmentRequestInput) {
          assignLicenseToScanAssetMatches(request: $request) {
            licenseId
            name
            category
            isOrgLicense
            orgId
            rejected
            attributionStatus
            license {
              licenseId
              name
              spdxId
              body
              description
              notes

              category
              style
              publicationYear
              type
              compatible
              incompatible
              isOrgLicense
              attributes {
                name
                type
                description
              }
            }
          }
        }
      `,
      { request }
    );
  }

  public updateLicenseOfScanComponents(scanComponentInput, licenseDataInput) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql(`
        mutation UpdateLicenseOfScanComponents(
          $scanComponentInput: ScanComponentInput!
          $licenseDataInput: LicenseDataInput!
        ) {
          updateLicenseOfScanComponents(
            scanComponent: $scanComponentInput
            license: $licenseDataInput
          ) {
            licenseId
            name
            category
            isOrgLicense
            orgId
            rejected
            license {
              customerLicenseId
              licenseId
              name
              spdxId
              body
              description
              notes
              category
              style
              publicationYear
              type
              compatible
              incompatible
              isOrgLicense
              attributes {
                name
                type
                description
              }
            }
          }
        }
      `),
      { scanComponentInput, licenseDataInput }
    );
  }

  addCopyrightToScanComponent(scanComponent, copyright) {
    const scanId = scanComponent.scanId;
    const componentPurl = scanComponent.componentId;
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql`
        mutation ($scanId: UUID, $componentPurl: String, $copyright: String) {
          addCopyrightToScanComponent(
            scanId: $scanId
            componentPurl: $componentPurl
            copyright: $copyright
          ) {
            scanId
            scanComponentCopyrights {
              text
              rejected
            }
          }
        }
      `,
      { scanId, componentPurl, copyright }
    );
  }

  setRejectedComponentLicense(scanId, componentPurl, licenseId) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($scanId: UUID, $componentPurl: String, $licenseId: String) {
          setRejectedComponentLicense(
            scanId: $scanId
            componentPurl: $componentPurl
            licenseId: $licenseId
          )
        }
      `,
      { scanId, componentPurl, licenseId }
    );
  }

  setRejectedComponentCopyright(scanId, componentPurl, copyright) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($scanId: UUID, $componentPurl: String, $copyright: String) {
          setRejectedComponentCopyright(
            scanId: $scanId
            componentPurl: $componentPurl
            copyright: $copyright
          ) {
            text
            rejected
          }
        }
      `,
      { scanId, componentPurl, copyright }
    );
  }
}
