<app-ui-modal #componentAddDialog>
  <!-- HEADER ================================================================================================= -->
  <div class="app-modal-header">
    <h5 class="modal-title">{{ editing ? 'Edit ' : 'Add ' }} Component</h5>
    <button
      type="button"
      class="close basic-close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <!-- BODY ================================================================================================= -->
  <div class="app-modal-body">
    <!-- COMPONENT NAME  -->

    <div class="title-area">
      <p class="component-search-title">You're about to add a custom component to the binary asset.
        Create a custom component from scratch or lookup a component in our knowledge base and apply it.</p>
      <p class="component-search-subtitle">Look up a component in the knowledge base</p>
    </div>

    <div class="component-form-section component-search-form">
      <div class="component-search">
        <div class="name-input-area">
          <div class="info-cols"><small>Name</small></div>
          <div ngbDropdown #compDropdown="ngbDropdown">
            <input
              [(ngModel)]="lookupName"
              type="text"
              required
              [pattern]="'^[^ ]*$'"
              class="form-control compliance-dashboard-add-component-dialog-input"
            />
            <div
              class="dropdown-list"
              ngbDropdownMenu
              aria-labelledby="dropdownManual"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollUpDistance]="1.5"
              [scrollWindow]="false"
              [infiniteScrollThrottle]="50"
              (scrolled)="loadMoreComponents()"
            >
              <div
                *ngFor="let component of componentList; trackBy: trackByFn"
                class="dropdown-item"
                ngbDropdownItem
                (click)="onComponentSelect(component)"
              >
                <p class="dropdown-name">
                  <span class="dropdown-subtitle">name:</span>
                  {{component.name}}
                </p>
                <p *ngIf="component.namespace" class="dropdown-info">
                  <span class="dropdown-subtitle">namespace:</span>
                  {{component.namespace}}
                </p>
                <p *ngIf="component.type" class="dropdown-info">
                  <span class="dropdown-subtitle">type:</span>
                  {{component.type}}
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div class="namespace-input-area">
            <div class="info-cols"><small>Namespace</small></div>
            <input
              [(ngModel)]="lookupNamespace"
              type="text"
              class="form-control compliance-dashboard-add-component-dialog-input"
            />
        </div>
  
        <div class="type-input-area">
          <div class="info-cols"><small>Type</small></div>
          <div>
            <select
              name="purlType"
              class="form-control custom-select"
              [(ngModel)]="lookupType"
              required
            >
              <option [ngValue]="type.id" *ngFor="let type of purTypes">
                {{ type.name }}
              </option>
            </select>
            <div
              class="invalid-form"
              *ngIf="
                purlTypeModel.errors &&
                (purlTypeModel.dirty || purlTypeModel.touched)
              "
            >
              <small
                *ngIf="purlTypeModel.errors && purlTypeModel.errors?.required"
                >Purl type is required.</small
              >
            </div>
          </div>
        </div>
  
        <button
          (click)="onFindComponentClick()"
          type="button"
          class="btn btn-primary search-button"
          [disabled]="!lookupName"
        >
          <i *ngIf="!isComponentLoading" class="fa-solid fa-magnifying-glass"></i>
          <i *ngIf="isComponentLoading" class="fa-solid fa-spinner fa-spin"></i>
        </button>
      </div>
      
      <p *ngIf="isNoComponentFound" class="name-warning">No matching components found for provided search arguments.</p>
      <small *ngIf="hasComponentLoadingError" class="invalid-form">An error occurred while loading data. Please try again later.</small>
    </div>

    <!-- COMPONENT NAME  -->

    <div class="component-form-section name-area">
      <div class="info-cols"><small>Component name</small></div>
      <input
        [(ngModel)]="componentToAdd.name"
        type="text"
        #nameModel="ngModel"
        required
        [pattern]="'^[^ ]*$'"
        class="form-control compliance-dashboard-add-component-dialog-input"
        placeholder="Type component name"
      />
      <div
        class="invalid-form"
        *ngIf="nameModel.errors && (nameModel.dirty || nameModel.touched)"
      >
        <small *ngIf="nameModel.errors?.required">Name is required.</small>
        <small *ngIf="nameModel.errors?.pattern"
          >Spaces are not allowed.</small
        >
      </div>
    </div>
      

    <!-- NAMESPACE and VERSION  -->

    <div class="component-form-section component-info">
      <div class="double-field-area">
        <div class="info-cols"><small>Component namespace</small></div>
        <input
          [(ngModel)]="componentToAdd.namespace"
          type="text"
          class="form-control compliance-dashboard-add-component-dialog-input"
          placeholder="Optional field"
        />
      </div>
  
      <div class="double-field-area">
        <div class="info-cols"><small>Component version</small></div>
        <input
          [(ngModel)]="componentToAdd.version"
          type="text"
          #versionModel="ngModel"
          required
          class="form-control compliance-dashboard-add-component-dialog-input"
          [ngbTypeahead]="versionSearch"
          #versionInputRef
          (focus)="onVersionInputFocus(versionInputRef)"
          required
          (selectItem)="setComponentVersion($event)"
        />
        <div
          class="invalid-form"
          *ngIf="
            versionModel?.errors && (versionModel?.dirty || versionModel?.touched)
          "
        >
          <small *ngIf="versionModel?.errors && versionModel.errors?.required"
            >Version is required.</small
          >
        </div>
      </div>
    </div>


    <!-- RELEASE DATE and PURL TYPE -->

    <div class="component-form-section component-info">
      <div class="double-field-area">
        <div class="info-cols"><small>Release Date</small></div>
        <div class="input-group">
          <input
            class="form-control compliance-dashboard-add-component-dialog-input"
            placeholder="Select a date"
            name="dp"
            [(ngModel)]="componentToAdd.releaseDate"
            ngbDatepicker
            #d="ngbDatepicker"
            #dateModel="ngModel"
            [minDate]="minDate"
            [maxDate]="maxDate"
          />
          <button
            class="btn btn-outline-secondary"
            (click)="d.toggle()"
            type="button"
          >
            <i class="fa-solid fa-calendar-days"></i>
          </button>
        </div>
      </div>

      <div class="double-field-area">
        <div class="info-cols"><small>Purl type</small></div>
        <div>
          <select
            name="purlType"
            class="form-control custom-select"
            [(ngModel)]="componentToAdd.purlType"
            #purlTypeModel="ngModel"
            required
          >
            <option [ngValue]="type.id" *ngFor="let type of purTypes">
              {{ type.name }}
            </option>
          </select>
          <div
            class="invalid-form"
            *ngIf="
              purlTypeModel.errors &&
              (purlTypeModel.dirty || purlTypeModel.touched)
            "
          >
            <small
              *ngIf="purlTypeModel.errors && purlTypeModel.errors?.required"
              >Purl type is required.</small
            >
          </div>
        </div>
      </div>
    </div>

    <!-- LICENSES -->
    <div class="component-form-section component-license-search">
      <div class="info-cols">
        <small>Search license to add to component</small>
      </div>
      <input
        id="typeahead-format"
        type="text"
        [(ngModel)]="licenseToAdd"
        class="form-control"
        placeholder="Type name of existing license"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [editable]="false"
        (selectItem)="setLicenseProperties($event)"
      />
    </div>

    <div class="row mb-2" *ngFor="let license of componentToAdd.licenses">
      <!-- NAME -->
      <div class="col-sm-6">
        <div class="info-cols"><small>License name</small></div>
        <input
          [(ngModel)]="license.name"
          type="text"
          class="form-control compliance-dashboard-add-component-dialog-input"
        />
      </div>

      <!-- CATEGORY -->
      <div class="col-sm-5">
        <div class="info-cols"><small>Category</small></div>
        <div>
          <select
            name="typeFilter"
            class="form-control custom-select"
            [ngClass]="licenseCategoryModel.value"
            [(ngModel)]="license.category"
            #licenseCategoryModel="ngModel"
            required
          >
            <option value=""></option>
            <option [value]="category.id" *ngFor="let category of categories">
              {{ category.name }}
            </option>
          </select>
          <div
            class="invalid-form"
            *ngIf="
              licenseCategoryModel.errors &&
              (licenseCategoryModel.dirty || licenseCategoryModel.touched)
            "
          >
            <small
              *ngIf="
                licenseCategoryModel.errors &&
                licenseCategoryModel.errors?.required
              "
              >License category name is required.</small
            >
          </div>
        </div>
      </div>

      <div class="col-sm-1">
        <i class="fa fa-trash" (click)="deleteLicense(license.licenseId)"></i>
      </div>
    </div>

    <!-- TEXT -->
    <div class="info-cols"><small>Copyright text</small></div>
    <textarea
      [(ngModel)]="componentToAdd.copyright"
      type="text"
      class="form-control compliance-dashboard-add-component-dialog-input"
    ></textarea>

    <div
      class="col-sm-6"
      style="
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding-left: 2px;
      "
    >
      <div class="info-cols"><small>Internal</small></div>
      <input [(ngModel)]="componentToAdd.isInternal" type="checkbox" />
    </div>
  </div>

  <!-- FOOTER ================================================================================================= -->
  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="close()"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary ml-2"
      (click)="saveComponent()"
    >
      {{ editing ? 'Save ' : 'Add ' }} Component
    </button>
  </div>
</app-ui-modal>
