import { Component, Input } from '@angular/core';
import { NextConfig } from '../../../../../../app-config';
import { Location } from '@angular/common';
import { AuthorizationService } from '@app/security/services';
import { NavigationItem } from '../../navigation.model';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  @Input() item: NavigationItem;
  public nextConfig: any = NextConfig.config;
  public themeLayout: string = this.nextConfig['layout'];

  public constructor(
    private location: Location,
    private authorizationService: AuthorizationService
  ) {}

  public closeOtherMenu(event: Event): void {
    if (this.themeLayout !== 'vertical') {
      return this.highlightActiveMenu();
    }

    const element = event.target as HTMLElement;
    if (!element) {
      return;
    }

    const parent = element.parentElement;
    const upParent = parent?.parentElement?.parentElement;
    const lastParent = upParent?.parentElement;

    document
      .querySelectorAll('.pcoded-hasmenu')
      .forEach((el) => el.classList.remove('active', 'pcoded-trigger'));

    [parent, upParent, lastParent].forEach((el) => {
      if (el?.classList.contains('pcoded-hasmenu')) {
        el.classList.add('pcoded-trigger', 'active');
      }
    });

    document
      .querySelector('app-navigation.pcoded-navbar.mob-open')
      ?.classList.remove('mob-open');
  }

  private highlightActiveMenu(): void {
    setTimeout(() => {
      document
        .querySelectorAll('.pcoded-hasmenu')
        .forEach((el) => el.classList.remove('active', 'pcoded-trigger'));

      let currentUrl = this.location.path();
      if (this.location['baseHref']) {
        currentUrl = this.location['baseHref'] + currentUrl;
      }

      const selector = `a.nav-link[href='${currentUrl}']`;
      const activeElement = document.querySelector(selector);
      if (!activeElement) {
        return;
      }

      const parent = activeElement.parentElement;
      const upParent = parent?.parentElement?.parentElement;
      const lastParent = upParent?.parentElement;

      [parent, upParent, lastParent].forEach((el) => {
        if (el?.classList.contains('pcoded-hasmenu')) {
          el.classList.add('active');
        }
      });
    }, 500);
  }

  public checkPermissions(item: NavigationItem): boolean {
    if (!item?.type) {
      return true;
    }

    if (item.type === 'group' || item.type === 'collapse') {
      return (
        item.children?.some((child) => this.checkPermissions(child)) || false
      );
    }

    return this.authorizationService.hasPermissions(item.permissions);
  }
}
