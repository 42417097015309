import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class KBService {
  constructor(private coreGraphQLService: CoreGraphQLService) {}

  public getKbComponents(
    nameFilter: string,
    namespaceFilter: string,
    pageable: {
      size: number;
      page: number;
    },
    typeFilter: string
  ) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        query KbComponents(
          $name: String
          $namespace: String
          $pageable: GraphQLJPAPageableInput
          $type: String
        ) {
          kbComponents(name: $name, namespace: $namespace, pageable: $pageable, type: $type) {
            content {
              description
              name
              nameLowercase
              namespace
              purl
              purlHashCode
              schema
              sourceRepositoryPurl
              trusted
              type
              web_address
            }
            pageInfo {
              pageNumber
              pageSize
              totalElements
              totalPages
            }
          }
        }
      `,
      {
        name: nameFilter,
        namespace: namespaceFilter,
        pageable: {
          size: pageable.size,
          page: pageable.page,
        },
        type: typeFilter,
      }
    );
  }

  public getKbLatestComponentRelease(purl) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        query KbLatestComponentRelease($purl: String) {
          kbLatestComponentRelease(purl: $purl) {
            componentId
            name
            group
            version
            isInternal
            description
            purl
            releaseDate
            copyrightList {
              text
            }

            licenses {
              edges {
                node {
                  licenseId
                  name
                  category
                  style
                  type
                  spdxId
                  publicationYear
                  isOsiApproved
                  isFsfLibre
                }
              }
            }
            allVulns {
              vulnerabilityId
              vulnerabilityAlias
              vulnerableVersions
              patchedVersions
              published
              cwe {
                cweId
                name
              }
              epss {
                epssPercent
                date
                percentilePercent
              }
              cvssV2BaseScore
              cvssV3BaseScore
              severity
              fixInfo {
                fixedVersion
                introducedVersion
                lastAffectedVersion
              }
            }

            componentVersions(pageSize: 25, start: 0) {
              pageSize
              start
              total
              resultList {
                componentId
                purl
                version
                releaseDate
                licenses {
                  edges {
                    node {
                      licenseId
                      name
                    }
                  }
                }

                vulnerabilities {
                  edges {
                    node {
                      severity
                      vulnerabilityAlias
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        purl,
      }
    );
  }

  public getKbComponentRelease(purl) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        query KbComponentRelease($purl: String) {
          kbComponentRelease(purl: $purl) {
            componentId
            name
            group
            version
            isInternal
            description
            purl
            releaseDate
            copyrightList {
              text
            }

            licenses {
              edges {
                node {
                  licenseId
                  name
                  category
                  style
                  type
                  spdxId
                  publicationYear
                  isOsiApproved
                  isFsfLibre
                }
              }
            }

            vulnerabilities {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }

              totalCount

              edges {
                node {
                  vulnerabilityId
                  vulnerabilityAlias
                  vulnerableVersions
                  patchedVersions
                  published
                  cwe {
                    cweId
                    name
                  }
                  epss {
                    epssPercent
                    date
                    percentilePercent
                  }
                  cvssV2BaseScore
                  cvssV3BaseScore
                  severity
                }
              }
            }

            componentVersions(pageSize: 25, start: 0) {
              pageSize
              start
              total
              resultList {
                componentId
                purl
                version
                releaseDate
                licenses {
                  edges {
                    node {
                      licenseId
                      name
                    }
                  }
                }

                vulnerabilities {
                  edges {
                    node {
                      severity
                      vulnerabilityAlias
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        purl,
      }
    );
  }
}
