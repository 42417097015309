import { PageInfo } from '@app/models/paging';

export class Vulnerability {
  vulnerabilityId: string;
  title: string;
  subtitle: string;
  description: string;
  references: string;
  published: string;
  updated: string;
  cwe: Cwe;
  cvssV2BaseScore: number;
  cvssV2ImpactSubScore: string;
  cvssV2ExploitabilitySubScore: string;
  cvssV2Vector: string;
  cvssV3BaseScore: string;
  cvssV3ImpactSubScore: string;
  cvssV3ExploitabilitySubScore: string;
  cvssV3Vector: string;
  severity: string;
  recommendation: string;
  credits: string;
  vulnerableVersions: string;
  patchedVersions: string;
  components: any;
  vulnerabilityAlias: string;
  source: string;
  vulnJiraTicket: {
    key: string;
    self: string;
  };
  firstComponentForVuln: any;
  epss?: {
    date: Date;
    epssPercent: number;
    percentilePercent: number;
  };
  fixInfo?: {
    fixedVersion: string;
    introducedVersion: string;
    lastAffectedVersion: string;
  };
}

export class Cwe {
  cweId: string;
  name: string;
}

export type VulnerabilityQuery = {
  vulnerability: Vulnerability;
};

export class ScanVulnerabilityEdge {
  edges: VulnerabilityEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

export class VulnerabilityEdge {
  node: Vulnerability;
  cursor: string;

  constructor(vuln: Vulnerability) {
    this.node = vuln;
  }
}

export interface VulnerabilityEdgeInterface {
  node: Vulnerability;
}

// currently doesn't used
export enum VulnerabiliesTypes {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  INFO = 'INFO',
}
